import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MainButton } from '../buttons/MainButton';
import { Input } from '../inputs/Input';
import { Textarea } from '../inputs/Textarea';
import { ModalFailure } from '../modals/ModalFailure';
import { ModalSuccess } from '../modals/ModalSuccess';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { breakpoints } from '../../styles/variables';
import { scrollToElementById } from '../../utils/ScrollToElementById';
import {
  validateContactForm,
  ValidateContactFormProps,
} from './validators/ValidateContactForm';
import { UserService } from '../../services/userService';
import { useTranslation } from 'react-i18next';

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.desktop}) {
    width: 50vw;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

export const ContactForm = () => {
  const { t } = useTranslation();

  const [contactParams, setContactParams] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    text: '',
  });

  const [status, setStatus] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });
  const [errors, setErrors] = useState<ValidateContactFormProps>({});

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setStatus((prevState) => {
      return {
        ...prevState,
        isSending: true,
      };
    });

    const validateErrors = validateContactForm(contactParams);

    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });

      return;
    }

    try {
      await UserService.sendEmail(contactParams);

      setStatus((prevState) => {
        return {
          ...prevState,
          isSent: true,
          isSending: false,
        };
      });
    } catch (err) {
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });
    }
  };

  useEffect(() => {
    scrollToElementById('spinner');
    scrollToElementById('modal');
  }, [status]);

  return (
    <FormContainer onSubmit={submitHandler}>
      <Input
        label={t('contactForm.name')}
        type="text"
        value={contactParams.name}
        onChange={(e) => {
          setContactParams((prevState) => {
            return { ...prevState, name: e.target.value };
          });
          setErrors({ ...errors, name: undefined });
        }}
        required
        error={errors.name}
      />
      <Input
        label={t('contactForm.email')}
        type="text"
        value={contactParams.email}
        onChange={(e) => {
          setContactParams((prevState) => {
            return { ...prevState, email: e.target.value };
          });
          setErrors({ ...errors, email: undefined });
        }}
        required
        error={errors.email}
      />
      <Input
        label={t('contactForm.phoneNumber')}
        type="text"
        value={contactParams.phoneNumber}
        onChange={(e) => {
          setContactParams((prevState) => {
            return { ...prevState, phoneNumber: e.target.value };
          });
          setErrors({ ...errors, phoneNumber: undefined });
        }}
        required
        error={errors.phoneNumber}
      />
      <Textarea
        label={t('contactForm.content')}
        value={contactParams.text}
        onChange={(e) => {
          setContactParams((prevState) => {
            return { ...prevState, text: e.target.value };
          });
          setErrors({ ...errors, text: undefined });
        }}
        required
        error={errors.text}
      />
      {status.isSent && (
        <ModalSuccess
          title={t('contactForm.modalSent')}
          onClose={() =>
            setStatus((prevState) => {
              return { ...prevState, isSent: false };
            })
          }
        />
      )}
      {status.isError && (
        <ModalFailure
          onClose={() =>
            setStatus((prevState) => {
              return { ...prevState, isError: false };
            })
          }
        />
      )}
      <ButtonWrapper>
        <MainButton
          title={t('buttons.send')}
          type="submit"
          disabled={status.isSending}
        />
      </ButtonWrapper>
      {status.isSending && <LoadingSpinner />}
    </FormContainer>
  );
};
