import styled from 'styled-components';
import { PartnerNavbar } from '../navigationPartner/PartnerNavbar';
import paw from '../../assets/icons/paw.png';
import { ReactNode } from 'react';
import { Wrapper } from './Layout.styles';
import { breakpoints } from '../../styles/variables';

const PartnerContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

const PartnerWrapper = styled(Wrapper)`
  background: linear-gradient(135deg, #007d96 -12.5%, #00a5c5 113.5%);
  height: calc(100vh - 4rem);
  width: calc(100vw - 2rem);

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: calc(100vw - 21rem);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: calc(100vw - 23rem);
  }

  @media screen and (min-width: ${breakpoints.widescreen}) {
    width: calc(100vw - 27rem);
  }

  @media screen and (min-width: ${breakpoints.uhd}) {
    width: calc(100vw - 37rem);
  }
`;

const PawWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  img {
    height: 65vh;
  }
`;

interface Props {
  children: ReactNode;
}

export default function PartnerLayout(props: Props) {
  const { children } = props;

  return (
    <PartnerContainer>
      <PartnerNavbar />
      <PartnerWrapper>{children}</PartnerWrapper>
      <PawWrapper>
        <img src={paw} alt="paw" />
      </PawWrapper>
    </PartnerContainer>
  );
}
