import { Box } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

const Container = styled(Box)`
  padding: 1.5rem;
  background-color: ${colors.white};
  border-radius: 1rem;
  height: calc(100% - 3rem);
  overflow: auto;
`;

interface MainContainerProps {
  children: ReactNode;
}

export const MainContainer = (props: MainContainerProps) => {
  const { children } = props;

  return <Container>{children}</Container>;
};
