import styled from 'styled-components';
import { AcceptedDogsEnum } from '../../enums/AcceptedDogsEnum';
import smallDog from '../../assets/icons/smallDog.svg';
import smallDogSelected from '../../assets/icons/smallDogSelected.svg';
import mediumDog from '../../assets/icons/mediumDog.svg';
import mediumDogSelected from '../../assets/icons/mediumDogSelected.svg';
import bigDog from '../../assets/icons/bigDog.svg';
import bigDogSelected from '../../assets/icons/bigDogSelected.svg';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../../styles/variables';

const Wrapper = styled.div`
  display: flex;
  gap: 3rem;
  margin-bottom: 1rem;
`;

const AcceptedDogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  width: 3rem;
  height: auto;

  @media (min-width: ${breakpoints.tablet}) {
    width: 6rem;
  }
`;

interface AcceptedDogProps {
  acceptedDogs: AcceptedDogsEnum[];
  setAcceptedDogs: React.Dispatch<React.SetStateAction<AcceptedDogsEnum[]>>;
}

export const AcceptedDog = (props: AcceptedDogProps) => {
  const { t } = useTranslation();
  const { acceptedDogs, setAcceptedDogs } = props;

  const imgClickHandler = (size: AcceptedDogsEnum) => {
    if (acceptedDogs?.includes(size)) {
      setAcceptedDogs((prevState) => {
        return prevState.filter((item) => item !== size);
      });
    } else {
      setAcceptedDogs((prevState) => [...prevState, size]);
    }
  };

  const isSizeSelected = (size: AcceptedDogsEnum) => {
    return acceptedDogs?.includes(size);
  };

  return (
    <>
      <p>{t('place.acceptedDogs')}</p>
      <Wrapper>
        <AcceptedDogWrapper
          onClick={() => imgClickHandler(AcceptedDogsEnum.Small)}
        >
          {isSizeSelected(AcceptedDogsEnum.Small) ? (
            <img src={smallDogSelected} alt="small-dog-selected" />
          ) : (
            <img src={smallDog} alt="small-dog" />
          )}
          <p>{t('acceptedDogs.small')}</p>
        </AcceptedDogWrapper>
        <AcceptedDogWrapper
          onClick={() => imgClickHandler(AcceptedDogsEnum.Medium)}
        >
          {isSizeSelected(AcceptedDogsEnum.Medium) ? (
            <img src={mediumDogSelected} alt="dog-selected" />
          ) : (
            <img src={mediumDog} alt="dog" />
          )}
          <p>{t('acceptedDogs.medium')}</p>
        </AcceptedDogWrapper>
        <AcceptedDogWrapper
          onClick={() => imgClickHandler(AcceptedDogsEnum.Big)}
        >
          {isSizeSelected(AcceptedDogsEnum.Big) ? (
            <img src={bigDogSelected} alt="big-dog-selected" />
          ) : (
            <img src={bigDog} alt="big-dog" />
          )}
          <p>{t('acceptedDogs.big')}</p>
        </AcceptedDogWrapper>
      </Wrapper>
    </>
  );
};
