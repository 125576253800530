import { useNavigate } from 'react-router';
import { MainContent } from '../../../components/grid/MainContent';
import { Paths } from '../../../enums/Paths';
import { FormTypes } from '../../../interfaces/FormTypes';
import { PartnerPlaceForm } from '../homepage/components/edit/PartnerPlaceForm';

export const PartnerAddPlace = () => {
  const navigate = useNavigate();

  return (
    <MainContent hideCards>
      <PartnerPlaceForm
        type={FormTypes.add}
        onAddSuccess={() => navigate(Paths.partnerHome)}
      />
    </MainContent>
  );
};
