import { ContactEmail } from '../interfaces/ContactEmail';
import { Place } from '../interfaces/Places';
import api from './api';

export class UserService {
  static async addUserAsPartner(email: string) {
    const res = await api.put('/users/add-partner', { email });

    return res.data;
  }

  static async getCurrentUserOwnedPlaces(): Promise<Place[]> {
    const res = await api.get('/users/current/owned-places');

    return res.data;
  }

  static async sendEmail(body: ContactEmail) {
    const res = await api.post('/users/contact', body, {
      headers: {
        Authorization: process.env.REACT_APP_AUTH_HEADER_API_KEY ?? '',
      },
    });

    return res.data;
  }

  static async acceptPartnerPlaces(partnerId: string) {
    const res = await api.post(
      `/users/partner/${partnerId}/staged-owned-places/accept`,
    );

    return res.data;
  }

  static async editPartnerStagedPlaces(partnerId: string, placeIds: string[]) {
    const res = await api.put(
      `/users/partner/${partnerId}/staged-owned-places/edit`,
      {
        placeIds,
      },
    );

    return res.data;
  }

  static async deletePartnerStagedPlaces(partnerId: string) {
    const res = await api.delete(
      `/users/partner/${partnerId}/staged-owned-places`,
    );

    return res.data;
  }

  static async addUsersOwnedPlaceAsStaged(placeIds: string[]) {
    const res = await api.put('/users/current/owned-places/staged', {
      placeIds,
    });

    return res.data;
  }

  static async getUsersWithStagedOwnedPlaces() {
    const res = await api.get('/users/owned-places/staged');

    return res.data;
  }

  static async deleteCurrentUser() {
    const res = await api.delete('/users/current');

    return res.data;
  }
}
