import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../../hooks/useIsTablet';
import { useIsWidescreen } from '../../../hooks/useIsWidescreen';
import { PlacesService } from '../../../services/placesService';
import { ObjectType } from '../../../interfaces/ObjectType';
import { StyledLabel } from '../../inputs/Inputs.styles';

interface SelectFeatureButtonProps {
  category: string;
  selectedPetFeatures: string[];
  setSelectedPetFeatures: Dispatch<SetStateAction<string[]>>;
}

export const SelectFeatureButton = (props: SelectFeatureButtonProps) => {
  const { t } = useTranslation();

  const { selectedPetFeatures, setSelectedPetFeatures, category } = props;

  const [petFeatures, setPetFeatures] = useState([]);
  const [petFeaturesTranslations, setPetFeaturesTranslations] =
    useState<ObjectType>();

  useEffect(() => {
    const fetchPetFeatures = async () => {
      const petFeaturesRes = await PlacesService.getFeaturesByCategory(
        category,
      );
      const petFeaturesTranslationsRes =
        await PlacesService.getFeaturesTranslations();

      setPetFeatures(petFeaturesRes);
      setPetFeaturesTranslations(petFeaturesTranslationsRes);
    };

    fetchPetFeatures();
  }, [category]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedPetFeatures>,
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedPetFeatures(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const returnPetFeatures = () =>
    petFeatures.map((item: string, idx: number) => (
      <MenuItem key={idx} value={item}>
        {petFeaturesTranslations ? petFeaturesTranslations[item] : item}
      </MenuItem>
    ));

  const isTablet = useIsTablet();
  const isWidescreen = useIsWidescreen();

  const selectWidth = isTablet
    ? '100%'
    : isWidescreen
    ? 'calc(75% + 1.4rem)'
    : 'calc(50% + 1.4rem)';

  return (
    <>
      <StyledLabel>{t('place.features')}</StyledLabel>
      <Select
        multiple
        onChange={handleChange}
        value={selectedPetFeatures}
        style={{
          width: selectWidth,
        }}
        renderValue={(selected) =>
          `${t('select.selectedFeatures')}: ${selected.length}`
        }
      >
        {returnPetFeatures()}
      </Select>
    </>
  );
};
