import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEdit } from 'react-icons/bi';
import styled from 'styled-components';
import { IconButton } from '../../../../components/navigationPartner/buttons/IconButton';
import { ImageSender } from '../../../../interfaces/ImageSender';
import { PlacesService } from '../../../../services/placesService';
import { uploadImages } from '../../../../utils/UploadImages';
import { ImageContainer } from '../PartnerImages.styles';
import Switch from '@mui/material/Switch';
import { useRefetchPlace } from '../../../../hooks/useRefetchPlace';
import { breakpoints } from '../../../../styles/variables';

const Text = styled.span`
  font-weight: 500;
  font-size: 0.9rem;
  margin: 0;
`;

const AddImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 0 0 auto;
  }
`;

const GoogleImagesContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface AddImagesProps {
  placeId: string;
  setIsLoading: (value: boolean) => void;
  showGoogleImages: boolean;
}

export const AddImages = (props: AddImagesProps) => {
  const { t } = useTranslation();

  const { placeId, setIsLoading, showGoogleImages } = props;

  const refetchPlace = useRefetchPlace();

  const [isToggled, setIsToggled] = useState(showGoogleImages);

  const uploadPhotos = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || files.length === 0) return;

    setIsLoading(true);

    await uploadImages({
      images: files,
      placeId,
      sender: ImageSender.partner,
    });

    await refetchPlace(placeId);

    setIsLoading(false);
  };

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsToggled(e.target.checked);
    await PlacesService.toggleShowGoogleImages(placeId);
    await refetchPlace(placeId);
  };

  useEffect(() => {
    setIsToggled(showGoogleImages);
  }, [showGoogleImages]);

  return (
    <AddImagesContainer>
      <GoogleImagesContainer>
        <Text>{t('images.showGoogleImages')}</Text>
        <Switch checked={isToggled} onChange={handleToggle} />
      </GoogleImagesContainer>
      <ImageContainer>
        <input
          type="file"
          onChange={uploadPhotos}
          multiple={true}
          accept="image/png, image/jpeg, image/jpg"
        />
        <IconButton small text={t('images.addImages')} Icon={BiEdit} />
      </ImageContainer>
    </AddImagesContainer>
  );
};
