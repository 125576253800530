import {
  AddressComponent,
  AddressDetailsTypesEnum,
} from '../../interfaces/AutocompleteDetails';

export const findInAddress = (
  addressComponent: AddressComponent[],
  type: AddressDetailsTypesEnum,
): string | null => {
  let foundItem: string | null = null;

  addressComponent.forEach((element: AddressComponent) => {
    const item = element.types.find((elementType) => elementType === type);
    if (item) {
      foundItem = element.long_name;
    }
  });

  return foundItem;
};
