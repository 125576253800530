export interface ValidateEmailProps {
  email?: string;
}

export const ValidateEmail = (values: ValidateEmailProps) => {
  return (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  );
};
