import { Logo } from '../navigation/Logo';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from 'styled-components';
import ButtonsPartner from './buttons/ButtonsPartner';
import { useEffect, useState } from 'react';
import { useIsTablet } from '../../hooks/useIsTablet';
import { colors } from '../../styles/variables';
import { AiOutlineMenu } from 'react-icons/ai';

const Drawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    padding: 1.5rem;
    position: sticky;
    height: calc(100vh - 3rem);
    width: 14rem;
  }
`;

const MobileMenuShow = styled.div`
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  width: 30px;
  height: 30px;
  z-index: 10000;
  svg {
    width: 25px;
    height: 25px;
  }
`;

export const PartnerNavbar = () => {
  const isTablet = useIsTablet();

  const [isNavOpen, setIsNavOpen] = useState(true);

  useEffect(() => {
    setIsNavOpen(!isTablet);
  }, [isTablet]);

  return (
    <>
      {isTablet && (
        <MobileMenuShow onClick={() => setIsNavOpen((prevState) => !prevState)}>
          <AiOutlineMenu color={colors.black} />
        </MobileMenuShow>
      )}
      <Drawer
        anchor="left"
        open={true}
        onClose={() => setIsNavOpen(false)}
        onOpen={() => setIsNavOpen(true)}
        variant={isTablet ? 'temporary' : 'permanent'}
        style={{
          visibility: `${isNavOpen ? 'visible' : 'hidden'}`,
        }}
      >
        <Logo size={45} />
        <ButtonsPartner
          onNavClick={() => {
            if (isTablet) {
              setIsNavOpen(false);
            }
          }}
        />
      </Drawer>
    </>
  );
};
