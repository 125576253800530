import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPencil } from 'react-icons/bi';
import { RiImageAddFill } from 'react-icons/ri';
import styled from 'styled-components';
import { useRefetchPlace } from '../../../../hooks/useRefetchPlace';
import { ImageSender } from '../../../../interfaces/ImageSender';
import { breakpoints, colors } from '../../../../styles/variables';
import { uploadImages } from '../../../../utils/UploadImages';
import { ImageAttribute, ImageContainer } from '../PartnerImages.styles';
import { AddImages } from './AddImages';

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  img {
    height: 7rem;
    width: auto;
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const AddImage = styled(RiImageAddFill)`
  width: 6rem;
  height: 6rem;
  cursor: pointer;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0 0 0.5rem;
`;

const Description = styled.h2`
  font-weight: 500;
  color: ${colors.grey};
  margin: 0;
`;

const Pencil = styled(BiPencil)`
  width: 1.3rem;
  height: 1.3rem;
  color: ${colors.secondary};
`;

interface TitleSectionProps {
  title: string;
  avatar: string;
  placeId: string;
  setIsLoading: (value: boolean) => void;
  showGoogleImages: boolean;
}

export const TitleSection = (props: TitleSectionProps) => {
  const { t } = useTranslation();

  const { title, avatar, placeId, setIsLoading, showGoogleImages } = props;

  const refetchPlace = useRefetchPlace();

  const uploadAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    setIsLoading(true);

    await uploadImages({
      avatar: files,
      placeId,
      sender: ImageSender.partner,
    });

    await refetchPlace(placeId);

    setIsLoading(false);
  };

  return (
    <Container>
      {avatar ? (
        <ImageContainer>
          <img src={avatar} />
          <input
            type="file"
            onChange={uploadAvatar}
            multiple={false}
            accept="image/png, image/jpeg, image/jpg"
          />
          <ImageAttribute>
            <Pencil />
          </ImageAttribute>
        </ImageContainer>
      ) : (
        <ImageContainer>
          <AddImage />
          <input
            type="file"
            onChange={uploadAvatar}
            multiple={false}
            accept="image/png, image/jpeg, image/jpg"
          />
        </ImageContainer>
      )}
      <ColumnContainer>
        <Header>{title}</Header>
        <Description>{t('partner.description')}</Description>
      </ColumnContainer>
      <AddImages
        placeId={placeId}
        setIsLoading={setIsLoading}
        showGoogleImages={showGoogleImages}
      />
    </Container>
  );
};
