import { HorizontalDivider } from '../../../components/dividers/HorizontalDivider';
import { MainContent } from '../../../components/grid/MainContent';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { usePlace } from '../../../store/placeContext';
import { ColumnFlexContainer } from '../homepage/PartnerHomepage.styles';
import SettingsSection from './components/SettingsSection';
import { TitleSection } from './components/TitleSection';

export const PartnerSettings = () => {
  const { state: place } = usePlace();

  if (place) {
    return (
      <MainContent hideCards>
        <ColumnFlexContainer>
          <TitleSection />
          <HorizontalDivider />
          <SettingsSection />
        </ColumnFlexContainer>
      </MainContent>
    );
  } else {
    return (
      <MainContent hideCards>
        <LoadingSpinner />
      </MainContent>
    );
  }
};
