import styled from 'styled-components';
import { Review } from '../../../../interfaces/Reviews';
import { CgProfile } from 'react-icons/cg';
import { colors } from '../../../../styles/variables';
import { useTranslation } from 'react-i18next';
import { BiBell, BiBellOff } from 'react-icons/bi';
import { ModalConfirmation } from '../../../../components/modals/ModalConfirmation';
import { useState } from 'react';
import { PlacesService } from '../../../../services/placesService';
import {
  PlaceContextReviewsTypes,
  useReviews,
} from '../../../../store/reviewsContext';
import { ReviewsService } from '../../../../services/reviewsService';
import { WithLoadingProps } from '../../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../../hocs/WithLoading';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InsideFlexContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const ProfileIcon = styled(CgProfile)`
  height: 2rem;
  width: 2rem;
`;

const Name = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 1.2rem;
  color: ${colors.black};
`;

const Rating = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 0.9;
  color: ${colors.grey};
`;

const Comment = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 0.9;
  color: ${colors.secondary};
`;

const Bell = styled(BiBell)`
  color: ${colors.lightRed};
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

const BellReported = styled(BiBellOff)`
  color: ${colors.lightRed};
  width: 2rem;
  height: 2rem;
`;

interface CommentsSectionProps extends WithLoadingProps {
  comments: Review[];
}

const CommentsSection = (props: CommentsSectionProps) => {
  const { t } = useTranslation();
  const { dispatch } = useReviews();

  const { comments, setLoading } = props;

  const [reviewToReport, setReviewToReport] = useState<Review>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const bellClickHandler = (item: Review) => {
    setShowConfirmationModal(true);
    setReviewToReport(item);
  };

  const confirmReport = async () => {
    if (!reviewToReport) return;

    setLoading(true);

    await ReviewsService.reportReview(
      reviewToReport.placeId._id,
      reviewToReport?._id,
    );

    const data = await PlacesService.fetchPlaceReviews(
      reviewToReport.placeId._id,
    );

    setShowConfirmationModal(false);

    dispatch({
      type: PlaceContextReviewsTypes.setPlaceReviews,
      payload: data,
    });

    setLoading(false);
  };

  return (
    <>
      <MainContainer>
        {comments.map((comment: Review, idx: number) => (
          <FlexContainer key={idx}>
            <InsideFlexContainer>
              <ProfileIcon />
              <ColumnFlexContainer>
                <Name>{comment.userId.firstName}</Name>
                <Rating>
                  {t('comments.rating')}: {comment.rating}/5
                </Rating>
                <Comment>{comment.comment}</Comment>
              </ColumnFlexContainer>
            </InsideFlexContainer>
            {comment.isReported ? (
              <BellReported />
            ) : (
              <Bell onClick={bellClickHandler.bind(null, comment)} />
            )}
          </FlexContainer>
        ))}
      </MainContainer>
      {showConfirmationModal && (
        <ModalConfirmation
          onAccept={confirmReport}
          onClose={() => setShowConfirmationModal(false)}
          title={t('comments.modalConfirmation')}
        />
      )}
    </>
  );
};

export default withLoading(CommentsSection);
