import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 125, 150, 0.37);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: ${colors.white};
  border-radius: 6px;
  padding: 4vh 8vw;
  position: relative;
  width: 75vw;

  font-weight: 600;
  color: ${colors.black};

  @media (min-width: ${breakpoints.tablet}) {
    width: 50vw;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 40vw;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;
