import {
  FlexContainer,
  ImageContainer,
  Label,
  LabeledTextContainer,
  OuterFlex,
  Text,
} from '../../PartnerHomepage.styles';
import {
  MdAttachMoney,
  MdOutlineCalendarToday,
  MdOutlineDescription,
} from 'react-icons/md';
import { colors } from '../../../../../styles/variables';
import { RiNumbersFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { PlaceDetails } from '../../../../../interfaces/Places';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import { DetailsItem } from './DetailsItem';

interface OpeningHoursSectionProps {
  details: PlaceDetails;
}

export const OpeningHoursSection = (props: OpeningHoursSectionProps) => {
  const { details } = props;

  const { t } = useTranslation();

  return (
    <OuterFlex>
      <FlexContainer>
        <ImageContainer>
          <MdOutlineCalendarToday color={colors.secondary} />
        </ImageContainer>
        <LabeledTextContainer>
          <Label>{t('partner.google.openingHours')}</Label>
          {details.opening_hours?.weekday_text ? (
            details.opening_hours.weekday_text.map(
              (item: string, idx: number) => {
                return <Text key={idx}>{item}</Text>;
              },
            )
          ) : (
            <Text>{t('partner.google.noInfo')}</Text>
          )}
        </LabeledTextContainer>
      </FlexContainer>
      {details.editorial_summary?.overview && (
        <DetailsItem
          Image={MdOutlineDescription}
          label={t('partner.google.description')}
          text={details.editorial_summary.overview}
        />
      )}
      {details.user_ratings_total && (
        <DetailsItem
          Image={AiOutlineFieldNumber}
          label={t('partner.google.totalRatings')}
          text={details.user_ratings_total.toString()}
        />
      )}
      {details.rating && (
        <DetailsItem
          Image={RiNumbersFill}
          label={t('partner.google.rating')}
          text={details.rating.toString()}
        />
      )}
      {details.price_level && (
        <DetailsItem
          Image={MdAttachMoney}
          label={t('partner.google.priceLevel')}
          text={details.price_level.toString()}
        />
      )}
    </OuterFlex>
  );
};
