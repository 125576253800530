import { useIsTablet } from '../../../hooks/useIsTablet';
import { ButtonsDesktop } from './ButtonsDesktop';
import { ButtonsMobile } from './ButtonsMobile';

export const Buttons = () => {
  const isTablet = useIsTablet();

  if (isTablet === true) {
    return <ButtonsMobile />;
  } else if (isTablet === false) {
    return <ButtonsDesktop />;
  } else return null;
};
