import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../styles/variables';

const Text = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
`;

interface StyledLinkProps {
  active: string;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  text-decoration: none;
  padding: 0.2rem 1.2rem;
  color: ${colors.black};
  background-color: ${({ active }) =>
    active === 'true' ? colors.primary : colors.white};
  border-radius: 5rem;
`;

interface NavlinkProps {
  to: string;
  active: boolean;
  title: string;
  onClick: () => void;
}

export const Navlink = (props: NavlinkProps) => {
  const { to, active, title, onClick } = props;

  return (
    <StyledLink to={to} active={active.toString()} onClick={onClick}>
      <Text>{title}</Text>
    </StyledLink>
  );
};
