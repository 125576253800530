import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MainButton } from '../../../components/buttons/MainButton';
import { HorizontalDivider } from '../../../components/dividers/HorizontalDivider';
import { MainContent } from '../../../components/grid/MainContent';
import { ModalFailure } from '../../../components/modals/ModalFailure';
import { ModalSuccess } from '../../../components/modals/ModalSuccess';
import { AssignToPlaceButton } from '../../../components/navigationPartner/buttons/AssignToPlaceButton';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { UserService } from '../../../services/userService';

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;
`;

const Subtitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 300;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

export const AddPlaceFromList = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });
  const [selectedPlaces, setSelectedPlaces] = useState<string[]>([]);

  const sendOwnedPlaces = async () => {
    try {
      setStatus((prevState) => {
        return {
          ...prevState,
          isSending: true,
        };
      });

      if (selectedPlaces.length === 0) {
        setStatus((prevState) => {
          return {
            ...prevState,
            isSending: false,
            isError: true,
          };
        });

        return;
      }

      await UserService.addUsersOwnedPlaceAsStaged(selectedPlaces);

      setStatus((prevState) => {
        return {
          ...prevState,
          isSending: false,
          isSent: true,
        };
      });
    } catch {
      setStatus((prevState) => {
        return {
          ...prevState,
          isSending: false,
          isError: true,
        };
      });
    }
  };

  return (
    <MainContent hideCards>
      <Title>{t('addPlaceFromList.title')}</Title>
      <Subtitle>{t('addPlaceFromList.subtitle')}</Subtitle>
      <HorizontalDivider />
      <AssignToPlaceButton
        selectedPlaces={selectedPlaces}
        setSelectedPlaces={setSelectedPlaces}
      />
      <ButtonContainer>
        <MainButton title={t('buttons.send')} onClick={sendOwnedPlaces} />
      </ButtonContainer>
      {status.isSent && (
        <ModalSuccess
          onClose={() => {
            setStatus((prevState) => {
              return {
                ...prevState,
                isSent: false,
              };
            });
            setSelectedPlaces([]);
          }}
          title={t('addPlaceFromList.modalSuccess')}
        />
      )}
      {status.isError && (
        <ModalFailure
          onClose={() =>
            setStatus((prevState) => {
              return {
                ...prevState,
                isError: false,
              };
            })
          }
        />
      )}
      {status.isSending && <LoadingSpinner />}
    </MainContent>
  );
};
