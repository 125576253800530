import modalSuccessImg from '../../assets/images/modalSuccessImg.png';
import modalClose from '../../assets/icons/modalClose.svg';
import { CloseModal, Container, Wrapper } from './Modals.styles';

interface ModalSuccessProps {
  onClose: () => void;
  title: string;
}

export const ModalSuccess = (props: ModalSuccessProps) => {
  const { onClose, title } = props;

  return (
    <Wrapper>
      <Container id="modal">
        <CloseModal onClick={onClose}>
          <img src={modalClose} alt="modal-close" />
        </CloseModal>
        <img src={modalSuccessImg} alt="modal-success" />
        <p>{title}</p>
      </Container>
    </Wrapper>
  );
};
