import { Circles } from 'react-loader-spinner';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

interface LoadingSpinnerProps {
  noBackground?: boolean;
}

export const Wrapper = styled.div<LoadingSpinnerProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.noBackground ? 'none' : 'rgba(0, 125, 150, 0.37)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { noBackground } = props;

  return (
    <Wrapper noBackground={noBackground}>
      <div id="spinner">
        <Circles height="80" width="80" color={colors.primary} />
      </div>
    </Wrapper>
  );
};
