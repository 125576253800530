import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { MainButton } from '../../../../components/buttons/MainButton';
import { Input } from '../../../../components/inputs/Input';
import { ModalSuccess } from '../../../../components/modals/ModalSuccess';
import { Paths } from '../../../../enums/Paths';
import { WithLoadingProps } from '../../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../../hocs/WithLoading';
import { AuthService } from '../../../../services/authService';
import { colors } from '../../../../styles/variables';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 50%;
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

const SmallButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const ChangePasswordSection = (props: WithLoadingProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setLoading } = props;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const changePasswordHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      await AuthService.partnerChangePassword(
        oldPassword,
        newPassword,
        confirmationPassword,
      );

      setLoading(false);
      setShowModalSuccess(true);
    } catch (err: any) {
      setError('resetPasswordForm.error');
      setLoading(false);
    }
  };

  return (
    <>
      <FormContainer onSubmit={changePasswordHandler}>
        <Input
          value={oldPassword}
          label={t('resetPasswordForm.old')}
          type={showPassword ? 'text' : 'password'}
          required
          onChange={(e) => {
            setOldPassword(e.target.value);
            setError('');
          }}
          error={error}
        />
        <Input
          value={newPassword}
          label={t('resetPasswordForm.new')}
          type={showPassword ? 'text' : 'password'}
          required
          onChange={(e) => {
            setNewPassword(e.target.value);
            setError('');
          }}
          error={error}
        />
        <Input
          value={confirmationPassword}
          label={t('resetPasswordForm.confirm')}
          type={showPassword ? 'text' : 'password'}
          required
          onChange={(e) => {
            setConfirmationPassword(e.target.value);
            setError('');
          }}
          error={error}
        />
        <SmallButtonWrapper>
          <MainButton
            title={t('buttons.showPasswords')}
            onClick={() => setShowPassword((prevState) => !prevState)}
            small
            btnColor={colors.primary}
          />
        </SmallButtonWrapper>
        <ButtonWrapper>
          <MainButton type="submit" title={t('buttons.send')} />
        </ButtonWrapper>
      </FormContainer>
      {showModalSuccess && (
        <ModalSuccess
          onClose={() => {
            setShowModalSuccess(false);
            navigate(Paths.partnerHome);
          }}
          title={t('resetPasswordForm.modalSuccess')}
        />
      )}
    </>
  );
};

export default withLoading(ChangePasswordSection);
