import { LoginProps, RegisterPartnerProps } from '../interfaces/Auth';
import { UserRolesEnum } from '../enums/UserRolesEnum';
import api from './api';
import { DeviceTypeEnum } from '../enums/DeviceTypeEnum';

interface IResetPassword {
  newPassword: string;
  confirmationPassword: string;
}
export class AuthService {
  static async loginAdmin(loginData: LoginProps) {
    const res = await api.post('/auth/login-admin', loginData);

    return res.data;
  }

  static async registerPartner(data: RegisterPartnerProps) {
    const res = await api.post('/auth/register-partner', data);

    return res.data;
  }

  static async logout() {
    await api.post('/auth/logout');
  }

  static async authenticate() {
    const res = await api.get('/auth');

    if (res.status === 200) {
      localStorage.setItem('isAuthenticated', 'true');

      const userRoles = res.data.roles;
      if (userRoles.includes(UserRolesEnum.Admin)) {
        localStorage.setItem('role', UserRolesEnum.Admin);
      } else if (userRoles.includes(UserRolesEnum.Partner)) {
        localStorage.setItem('role', UserRolesEnum.Partner);
      }
    }
    return res;
  }

  static async sendResetPasswordLink(email: string) {
    const res = await api.post('/auth/reset-password-link', {
      email,
      deviceType: DeviceTypeEnum.Desktop,
    });

    return res.data;
  }

  static async resetPassword(token: string, passwords: IResetPassword) {
    await api.post(`/auth/reset-password/${token}`, passwords);
  }

  static async activateUserWithToken(userId: string, token: string) {
    const res = await api.post(`/auth/activate-partner/${userId}`, {
      token,
    });

    return res.data;
  }

  static async partnerChangePassword(
    oldPassword: string,
    newPassword: string,
    confirmationPassword: string,
  ) {
    const res = await api.put('/auth/partner/change-password', {
      oldPassword,
      newPassword,
      confirmationPassword,
    });

    return res.data;
  }
}
