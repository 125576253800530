import { useEffect, useState } from 'react';
import { Table } from '../../../components/table/Table';
import { baseColumns } from '../../../components/table/columns/TableBaseColumns';
import { PlacesService } from '../../../services/placesService';
import { FlattenedPlace, flattenPlaces } from './helpers/flattenPlaces';
import { useNavigate } from 'react-router';
import { ButtonColumn } from '../../../components/table/columns/ButtonColumn';
import ModalCarousel from '../../../components/modals/ModalCarousel';
import { ModalConfirmation } from '../../../components/modals/ModalConfirmation';
import { itemsPerPage } from '../../../styles/variables';
import { TableOptions } from '../../../components/table/tableOptions/TableOptions';
import { scrollToElementById } from '../../../utils/ScrollToElementById';
import { BiCarousel, BiImage, BiEdit, BiShow } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../../enums/Paths';
import { NoApplication } from '../../../components/noApplication/NoApplication';
import { WithLoadingProps } from '../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../hocs/WithLoading';
import { showTableOptions } from '../../../utils/ShowTableOptions';

const Unpublished = (props: WithLoadingProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setLoading } = props;

  const [places, setPlaces] = useState<FlattenedPlace[]>([]);

  const [showCarousel, setShowCarousel] = useState(false);
  const [images, setImages] = useState<string[]>([]);

  const [showAvatar, setShowAvatar] = useState(false);
  const [avatar, setAvatar] = useState<string[]>([]);

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [placeId, setPlaceId] = useState('');

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchData = async () => {
    setLoading(true);

    const query = `?page=${
      currentPage + 1
    }&limit=${itemsPerPage}&search=${search}&sort=-createdAt`;

    const { data, count } = await PlacesService.fetchUnpublishedPlaces(query);

    setPageCount(Math.ceil(count / itemsPerPage));

    setPlaces(flattenPlaces(data));

    setLoading(false);
  };

  const handleSearch = async () => {
    setCurrentPage(0);
    fetchData();
  };

  const publishPlaceClickHandler = (place: FlattenedPlace) => {
    setPlaceId(place.id);
    setShowPublishModal(true);
  };

  const publishPlace = async (id: string) => {
    await PlacesService.publishPlace(id);
    await fetchData();
    setShowPublishModal(false);
  };

  const showAvatarClickHandler = (place: FlattenedPlace) => {
    if (place.avatar) {
      setAvatar([place.avatar]);
    }
    setShowAvatar(true);
  };

  const showImagesClickHandler = (place: FlattenedPlace) => {
    setImages(place.images);
    setPlaceId(place.id);
    setShowCarousel(true);
  };

  const editPlaceClickHandler = (place: FlattenedPlace) => {
    navigate(Paths.adminEditPlace(place.id));
  };

  const deletePlaceClickHandler = async (place: FlattenedPlace) => {
    setPlaceId(place.id);
    setShowConfirmationModal(true);
  };

  const deletePlace = async (id: string) => {
    await PlacesService.deletePlace(id);
    await fetchData();
    setShowConfirmationModal(false);
  };

  const columns = [
    ...baseColumns(),
    ButtonColumn({
      id: 'button_1',
      onClick: showAvatarClickHandler,
      title: t('columns.buttons.avatar'),
      icon: <BiImage />,
    }),
    ButtonColumn({
      id: 'button_2',
      onClick: showImagesClickHandler,
      title: t('columns.buttons.images'),
      icon: <BiCarousel />,
    }),
    ButtonColumn({
      id: 'button_3',
      onClick: editPlaceClickHandler,
      title: t('columns.buttons.edit'),
      icon: <BiEdit />,
    }),
    ButtonColumn({
      id: 'button_4',
      onClick: deletePlaceClickHandler,
      title: t('columns.buttons.delete'),
      icon: <MdDelete />,
    }),
    ButtonColumn({
      id: 'button_5',
      onClick: publishPlaceClickHandler,
      title: t('columns.buttons.publish'),
      icon: <BiShow />,
    }),
  ];

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    scrollToElementById('modal');
    scrollToElementById('modal-carousel');
  }, [showConfirmationModal, showCarousel, showAvatar]);

  return (
    <>
      {showTableOptions(pageCount, search) && (
        <TableOptions
          paginationProps={{
            currentPage,
            pageCount,
            handlePageClick: async (e) => {
              setCurrentPage(e.selected);
            },
          }}
          searchBarProps={{
            value: search,
            handleSetValue: (e) => setSearch(e.target.value),
            handleSearch,
          }}
          header={t('admin.unpublishedTitle')}
        />
      )}
      {places && places.length > 0 ? (
        <Table columns={columns} data={places} />
      ) : (
        <NoApplication />
      )}
      {showAvatar && (
        <ModalCarousel
          images={avatar}
          onClose={async () => {
            setShowAvatar(false);
          }}
          showDelete={false}
        />
      )}
      {showCarousel && (
        <ModalCarousel
          images={images}
          placeId={placeId}
          onClose={async () => {
            await fetchData();
            setShowCarousel(false);
          }}
          showDelete={true}
        />
      )}
      {showConfirmationModal && (
        <ModalConfirmation
          onAccept={deletePlace.bind(null, placeId)}
          onClose={() => setShowConfirmationModal(false)}
          title={t('admin.deletePlace')}
        />
      )}
      {showPublishModal && (
        <ModalConfirmation
          onAccept={publishPlace.bind(null, placeId)}
          onClose={() => setShowPublishModal(false)}
          title={t('admin.publish')}
        />
      )}
    </>
  );
};

export default withLoading(Unpublished);
