import styled from 'styled-components';
import { colors } from '../../styles/variables';
import { FaSearch } from 'react-icons/fa';

const Button = styled.button`
  cursor: pointer;
  border: none;
  width: 3rem;
  background: ${colors.primary};
  border-radius: 6px;

  color: ${colors.white};
`;

interface MainButtonProps {
  onClick: () => void;
}

export const SearchButton = (props: MainButtonProps) => {
  const { onClick } = props;

  return (
    <Button type="button" onClick={onClick}>
      <FaSearch />
    </Button>
  );
};
