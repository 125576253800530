import styled from 'styled-components';
import { VerticalDivider } from '../../components/dividers/VerticalDivider';
import LoginForm from '../../components/forms/LoginForm';
import { breakpoints } from '../../styles/variables';
import { PartnerPanelInfo } from './components/PartnerPanelInfo';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints.desktop}) {
    gap: 5rem;
  }
`;

export const Login = () => {
  return (
    <>
      <FlexContainer>
        <LoginForm />
        <VerticalDivider />
        <PartnerPanelInfo />
      </FlexContainer>
    </>
  );
};
