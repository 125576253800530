import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
`;

const Header = styled.h1`
  font-size: 1.8rem;
  font-weight: 400;
`;

export const NoApplication = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('noApplication.title')}</Header>
    </Container>
  );
};
