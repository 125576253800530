import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PartnerCell } from '../../../interfaces/PartnerCell';

const PlaceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
`;

export const partnersColumns = () => {
  const { t } = useTranslation();

  return [
    {
      Header: t('columns.partners.email'),
      id: 'email',
      accessor: 'email',
    },
    {
      Header: t('columns.partners.firstName'),
      id: 'firstName',
      accessor: 'firstName',
    },
    {
      Header: t('columns.partners.surName'),
      id: 'lastName',
      accessor: 'lastName',
    },
    {
      Header: t('columns.partners.phoneNumber'),
      id: 'phoneNumber',
      accessor: 'phoneNumber',
    },
    {
      Header: t('columns.partners.places'),
      Cell: ({ row }: PartnerCell) => (
        <PlaceContainer>
          {row.original.stagedOwnedPlaces.map((item, idx: number) => {
            return (
              <Name key={idx}>
                {typeof item === 'string' ? item : item.name}
              </Name>
            );
          })}
        </PlaceContainer>
      ),
    },
  ];
};
