import { IconType } from 'react-icons';
import { colors } from '../../../../../styles/variables';
import {
  FlexContainer,
  ImageContainer,
  Label,
  LabeledTextContainer,
  Text,
} from '../../PartnerHomepage.styles';

interface DetailsItemProps {
  Image: IconType;
  label: string;
  text: string;
}

export const DetailsItem = (props: DetailsItemProps) => {
  const { Image, label, text } = props;

  return (
    <FlexContainer>
      <ImageContainer>
        <Image color={colors.secondary} />
      </ImageContainer>
      <LabeledTextContainer>
        <Label>{label}</Label>
        <Text>{text}</Text>
      </LabeledTextContainer>
    </FlexContainer>
  );
};
