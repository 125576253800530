import { useTranslation } from 'react-i18next';
import { MainContent } from '../../../components/grid/MainContent';
import { WithLoadingProps } from '../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../hocs/WithLoading';
import { useIsTablet } from '../../../hooks/useIsTablet';
import { usePlace } from '../../../store/placeContext';
import { ImageListSection } from './components/ImageListSection';
import { TitleSection } from './components/TitleSection';

const PartnerImages = (props: WithLoadingProps) => {
  const { setLoading } = props;

  const { t } = useTranslation();
  const { state: place } = usePlace();
  const isTablet = useIsTablet();

  const setIsLoadingFoo = (value: boolean) => setLoading(value);

  return (
    <>
      {place.name && (
        <MainContent hideCards={!!isTablet}>
          <TitleSection
            title={`${place.name} - ${t('place.images')}`}
            avatar={place.avatar}
            placeId={place._id}
            setIsLoading={setIsLoadingFoo}
            showGoogleImages={place.showGoogleImages ?? true}
          />
          <ImageListSection
            images={place.images}
            googleImages={place.details?.photos}
            placeId={place._id}
            setIsLoading={setIsLoadingFoo}
            showGoogleImages={place.showGoogleImages ?? true}
          />
        </MainContent>
      )}
    </>
  );
};

export default withLoading(PartnerImages);
