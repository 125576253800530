import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import modalClose from '../../assets/icons/modalClose.svg';
import { CloseModal, Container, Wrapper } from './Modals.styles';
import { useState } from 'react';
import { MainButton } from '../buttons/MainButton';
import styled from 'styled-components';
import { PlacesService } from '../../services/placesService';
import { FilesService } from '../../services/filesService';
import { ModalConfirmation } from './ModalConfirmation';
import { useTranslation } from 'react-i18next';
import { WithLoadingProps } from '../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../hocs/WithLoading';
import { CallbackDefault, CallbackSomeData } from '../../types/Types';

const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const Img = styled.img`
  object-fit: contain;
`;

interface CarouselProps extends WithLoadingProps {
  images: string[];
  removeImage?: CallbackSomeData<string>;
  placeId?: string;
  onClose: CallbackDefault;
  showDelete: boolean;
}

const ModalCarousel = (props: CarouselProps) => {
  const { t } = useTranslation();

  const { images, placeId, onClose, showDelete, removeImage, setLoading } =
    props;

  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const deleteImageHandler = async (image: string) => {
    if (placeId) await PlacesService.deleteImage(placeId, image);
    await FilesService.deleteFileFromS3(image);
  };

  const confirmDelete = async () => {
    setLoading(true);

    await deleteImageHandler(images[currentImageIdx]);
    setShowConfirmationModal(false);
    if (removeImage) {
      removeImage(images[currentImageIdx]);
      setCurrentImageIdx(0);
    }

    setLoading(false);
  };

  return (
    <Wrapper>
      <Container id="modal-carousel">
        <CloseModal onClick={onClose}>
          <img src={modalClose} alt="modal-close" />
        </CloseModal>
        {images?.length > 0 ? (
          <Carousel
            dynamicHeight
            showStatus={false}
            showThumbs={false}
            onChange={(e) => {
              setCurrentImageIdx(e);
            }}
          >
            {images.map((image, idx) => (
              <Img src={image} key={idx} />
            ))}
          </Carousel>
        ) : (
          <p>{t('images.noImages')}</p>
        )}

        {showDelete && (
          <ButtonWrapper>
            {images?.length > 0 && (
              <MainButton
                title={t('buttons.delete')}
                onClick={() => {
                  setShowConfirmationModal(true);
                }}
              />
            )}
          </ButtonWrapper>
        )}
      </Container>
      {showConfirmationModal && (
        <ModalConfirmation
          onAccept={confirmDelete}
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          title={t('modals.carousel')}
        />
      )}
    </Wrapper>
  );
};

export default withLoading(ModalCarousel);
