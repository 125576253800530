import { NodeEnvEnum } from '../enums/NodeEnvEnum';
import configDev from './default.json';
import configProd from './production.json';
import configStaging from './staging.json';

export const getConfig = () => {
  if (process.env.REACT_APP_ENV === NodeEnvEnum.Production) {
    return configProd;
  }
  if (process.env.REACT_APP_ENV === NodeEnvEnum.Staging) {
    return configStaging;
  }
  return configDev;
};
