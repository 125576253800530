import { useTable } from 'react-table';
import styled from 'styled-components';
import { Cell } from '../../interfaces/Cell';
import { FlattenedPlace } from '../../pages/admin/unpublished/helpers/flattenPlaces';

const TableContainer = styled.div`
  height: 80vh;
  width: 100%;
  overflow: scroll;
`;

const StyledTable = styled.table`
  width: 100%;
  margin-top: 1rem;
`;

const TableHead = styled.thead`
  text-align: left;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableDataCell = styled.td`
  padding: 0.2rem 0.4rem;
  width: auto;
`;

interface TableHeaderCellProps {
  isButton?: boolean;
}

const TableHeaderCell = styled.th<TableHeaderCellProps>`
  padding: 0.2rem 0.4rem;
  width: ${(props) => (props.isButton ? '32px' : 'auto')};
`;

interface TableProps {
  columns: Array<
    | {
        Header: string;
        id: string;
        accessor: string;
      }
    | {
        Header: string;
        Cell: ({ row }: Cell) => JSX.Element;
      }
    | any
  >;
  data: FlattenedPlace[];
}

const isButton = (id: string) => {
  return id.includes('button');
};

export const Table = (props: TableProps) => {
  const { columns, data } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const returnTableRows = () => {
    return rows.map((row) => {
      prepareRow(row);
      return (
        <TableRow {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return (
              <TableDataCell {...cell.getCellProps()}>
                {cell.render('Cell')}
              </TableDataCell>
            );
          })}
        </TableRow>
      );
    });
  };

  return (
    <TableContainer>
      <StyledTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell
                  {...column.getHeaderProps()}
                  isButton={isButton(column.id)}
                >
                  {column.render('Header')}
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>{returnTableRows()}</TableBody>
      </StyledTable>
    </TableContainer>
  );
};
