import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import modalClose from '../../assets/icons/modalClose.svg';
import { colors } from '../../styles/variables';
import { MainButton } from '../buttons/MainButton';
import { CloseModal, Container, Wrapper } from './Modals.styles';

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 3rem;
`;

interface ModalSuccessProps {
  onClose: () => void;
  onAccept: () => void;
  title: string;
}

export const ModalConfirmation = (props: ModalSuccessProps) => {
  const { t } = useTranslation();

  const { onClose, onAccept, title } = props;

  return (
    <Wrapper>
      <Container id="modal">
        <CloseModal onClick={onClose}>
          <img src={modalClose} alt="modal-close" />
        </CloseModal>
        <p>{title}</p>
        <ButtonsWrapper>
          <MainButton
            title={t('yes')}
            onClick={onAccept}
            btnColor={colors.primary}
          />
          <MainButton
            title={t('no')}
            onClick={onClose}
            btnColor={colors.asteriksRed}
          />
        </ButtonsWrapper>
      </Container>
    </Wrapper>
  );
};
