import { HorizontalDivider } from '../../../components/dividers/HorizontalDivider';
import { MainContent } from '../../../components/grid/MainContent';
import { ColumnFlexContainer } from '../homepage/PartnerHomepage.styles';
import ChangePasswordSection from './components/ChangePasswordSection';
import { TitleSection } from './components/TitleSection';

export const PartnerChangePassword = () => {
  return (
    <MainContent hideCards>
      <ColumnFlexContainer>
        <TitleSection />
        <HorizontalDivider />
        <ChangePasswordSection />
      </ColumnFlexContainer>
    </MainContent>
  );
};
