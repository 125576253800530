export enum AddressDetailsTypesEnum {
  state = 'administrative_area_level_1',
  city = 'locality',
  postalCode = 'postal_code',
  street = 'route',
  streetNumber = 'street_number',
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: AddressDetailsTypesEnum[];
}

export interface GoogleAutocompleteDetails {
  address_components?: AddressComponent[];
  editorial_summary?: {
    overview?: string;
  };
  formatted_phone_number?: string;
  name?: string;
  website?: string;
  place_id?: string;
}
