import styled from 'styled-components';
import { colors } from '../../../../styles/variables';

const FlexItem = styled.div`
  display: flex;
`;

interface TextProps {
  isDiff?: boolean;
}
const Text = styled.p<TextProps>`
  font-size: 1.1rem;
  width: 50%;
  text-align: center;
  line-break: anywhere;

  color: ${(props) => (props.isDiff ? colors.asteriksRed : colors.black)};
`;

const Header = styled.h2`
  font-size: 1.2rem;
  width: 30%;
  font-weight: 400;
`;

const Items = styled.div`
  width: 100%;
  display: flex;
`;

interface ChangesDiffProps {
  title: string;
  oldItem: string;
  newItem: string;
}

export const ComparedItem = (props: ChangesDiffProps) => {
  const { oldItem, newItem, title } = props;

  const compare = (oldComparedItem: string, newComparedItem: string) => {
    return oldComparedItem !== newComparedItem;
  };

  return (
    <FlexItem>
      <Header>{title}</Header>
      <Items>
        <Text>{oldItem}</Text>
        <Text isDiff={compare(oldItem, newItem)}>{newItem}</Text>
      </Items>
    </FlexItem>
  );
};
