import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContactForm } from '../../../components/forms/ContactForm';
import { MainContent } from '../../../components/grid/MainContent';
import { colors } from '../../../styles/variables';

const Content = styled.div`
  height: 100%;
  overflow: auto;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;

  a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 300;
    color: ${colors.secondary};
  }
`;

export const PartnerContact = () => {
  const { t } = useTranslation();

  return (
    <MainContent hideCards>
      <Content>
        <Title>
          {t('contactForm.title')}{' '}
          <a href="mailto:contact@walkiedoggie.io">contact@walkiedoggie.io</a>
        </Title>
        <ContactForm />
      </Content>
    </MainContent>
  );
};
