import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useRole } from '../../hooks/useRole';
import { UserRolesEnum } from '../../enums/UserRolesEnum';
import { Paths } from '../../enums/Paths';

export const PartnerRoute: React.FC = () => {
  const auth = useAuth();
  const role = useRole();

  if (auth && role === UserRolesEnum.Partner) {
    return <Outlet />;
  } else {
    return <Navigate to={Paths.login} />;
  }
};
