import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import errorIcon from '../../assets/icons/error.svg';
import { breakpoints, colors } from '../../styles/variables';
import { Error, ErrorContainer } from './Inputs.styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: 500;
  line-height: 1.8rem;
  padding-top: 0.6rem;

  color: ${colors.white};
  border-radius: 30px;
  text-align: center;
  width: 90%;

  background-color: #f1b016;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-bottom: 1rem;

  input {
    display: none;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 10rem;
  }
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 1rem;
`;

interface FileInputProps {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  multiple?: boolean;
}

export const FileInput = (props: FileInputProps) => {
  const { t } = useTranslation();

  const { label, onChange, error, multiple } = props;

  const [filesAdded, setFilesAdded] = useState(0);
  const [isByteLimitExcedeed, setIsByteLimitExcedeed] = useState(false);

  const byteLimit = 4000000;

  const addFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    setIsByteLimitExcedeed(false);
    setFilesAdded(0);

    for (let idx = 0; idx < files.length; idx++) {
      if (files[idx] && files[idx]!.size > byteLimit) {
        setIsByteLimitExcedeed(true);
        return;
      }
    }

    if (isByteLimitExcedeed) return;

    onChange(e);
    setFilesAdded(files.length);
  };

  return (
    <>
      <Container>
        <Label>
          Dodaj {label}
          <input
            type="file"
            onChange={addFileHandler}
            multiple={multiple}
            accept="image/png, image/jpeg, image/jpg"
          />
        </Label>
        {filesAdded > 0 && <Text>Ilość dodanych plików: {filesAdded}</Text>}
        {isByteLimitExcedeed && (
          <ErrorContainer>
            <img src={errorIcon} alt="error" />
            <Error>Przekroczono maksymalny rozmiar pliku</Error>
          </ErrorContainer>
        )}
      </Container>
      {error && (
        <ErrorContainer>
          <img src={errorIcon} alt="error" />
          <Error>{t(error)}</Error>
        </ErrorContainer>
      )}
    </>
  );
};
