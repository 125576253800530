import { useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../components/buttons/MainButton';
import { Input } from '../../components/inputs/Input';
import { ModalFailure } from '../../components/modals/ModalFailure';
import { KeyEnum } from '../../enums/KeyEnum';
import { Paths } from '../../enums/Paths';
import { WithLoadingProps } from '../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../hocs/WithLoading';
import {
  validateLogin,
  ValidateLoginProps,
} from '../../pages/login/helpers/ValidateLogin';
import { AuthService } from '../../services/authService';
import { breakpoints, colors } from '../../styles/variables';
import { Header } from '../header/Header';

const LinkContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.secondary};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    width: 50%;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

const LoginForm = (props: WithLoadingProps) => {
  const { loading, setLoading } = props;

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState<ValidateLoginProps>({});

  const submitHandler = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    setLoading(true);

    const validateLoginErrors = validateLogin({
      email,
      password,
    });

    if (Object.keys(validateLoginErrors).length > 0) {
      setIsError(true);
      setErrors(validateLoginErrors);
      setLoading(false);
      return;
    }

    try {
      await AuthService.loginAdmin({ email, password });
      await AuthService.authenticate();

      window.location.reload();
    } catch (errAdmin) {
      setIsError(true);
      setLoading(false);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyEnum.Enter) {
      submitHandler();
    }
  };

  return (
    <FormContainer onSubmit={submitHandler}>
      <Header>{t('loginForm.title')}</Header>
      <Input
        label={t('loginForm.email')}
        type="text"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setErrors({ ...errors, email: undefined });
        }}
        onKeyPress={handleKeyPress}
        required
        error={errors.email}
        fullWidth
      />
      <Input
        label={t('loginForm.password')}
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setErrors({ ...errors, password: undefined });
        }}
        required
        error={errors.password}
        fullWidth
      />
      {isError && <ModalFailure onClose={() => setIsError(false)} />}
      <LinkContainer>
        <StyledLink to={Paths.resetPasswordLink}>
          {t('loginForm.resetPassword')}
        </StyledLink>
      </LinkContainer>
      <ButtonContainer>
        <MainButton
          title={t('buttons.login')}
          type="submit"
          disabled={loading}
        />
      </ButtonContainer>
      <LinkContainer>
        {t('loginForm.noAccount')}
        <StyledLink to={Paths.register}>{t('loginForm.register')}</StyledLink>
      </LinkContainer>{' '}
      <LinkContainer>
        {t('loginForm.hasAccount')}
        <StyledLink to={Paths.alreadyRegistered}>
          {t('loginForm.alreadyRegistered')}
        </StyledLink>
      </LinkContainer>
    </FormContainer>
  );
};

export default withLoading(LoginForm);
