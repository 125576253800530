import { useState } from 'react';
import hamburger from '../../../assets/icons/hamburger.svg';
import hamburgerOpen from '../../../assets/icons/hamburgerOpen.svg';
import styled from 'styled-components';
import { colors } from '../../../styles/variables';
import { AuthService } from '../../../services/authService';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../../enums/Paths';
import { clearLocalStorage } from '../../../utils/ClearLocalStorage';

const HamburgerWrapper = styled.div`
  height: 33px;
  cursor: pointer;
`;

const OpenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const List = styled.ul`
  list-style: none;
  padding: 0.6rem 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.secondary};
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 55px;
  left: 0;
  width: 100vw;
  z-index: 999;
`;

interface ListItemProps {
  bold?: boolean;
}

const ListItem = styled.li<ListItemProps>`
  padding: 1.2rem 0;
  font-weight: ${(props) => (props.bold ? '700' : '500')};
  font-size: 1rem;
  line-height: 1.2rem;
`;

const Button = styled.a`
  text-decoration: none;
  color: ${colors.white};
`;

const Pipe = styled.div`
  height: 1px;
  width: 70vw;
  background: ${colors.white};
  margin: 1.2rem 0;
`;

export const ButtonsMobile = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const openHamburger = () => setIsOpen(true);
  const closeHamburger = () => setIsOpen(false);

  const logoutHandler = async () => {
    closeHamburger();
    await AuthService.logout();
    clearLocalStorage();
    window.location.reload();
  };

  return (
    <HamburgerWrapper>
      {isOpen ? (
        <OpenContainer>
          <img
            src={hamburgerOpen}
            width={33}
            height={33}
            alt="hamburger-open"
            onClick={closeHamburger}
            loading="eager"
          />
          <List>
            <ListItem>
              <Button href={Paths.admin} onClick={closeHamburger}>
                {t('navigationAdmin.published')}
              </Button>
            </ListItem>
            <ListItem>
              <Button href={Paths.adminUnpublished} onClick={closeHamburger}>
                {t('navigationAdmin.unpublished')}
              </Button>
            </ListItem>
            <ListItem>
              <Button href={Paths.adminStagedChanges} onClick={closeHamburger}>
                {t('navigationAdmin.newChanges')}
              </Button>
            </ListItem>
            <ListItem>
              <Button href={Paths.adminReviews} onClick={closeHamburger}>
                {t('navigationAdmin.reviews')}
              </Button>
            </ListItem>
            <ListItem>
              <Button href={Paths.adminPartners} onClick={closeHamburger}>
                {t('navigationAdmin.partners')}
              </Button>
            </ListItem>
            <ListItem>
              <Button href={Paths.adminAddPlace} onClick={closeHamburger}>
                {t('navigationAdmin.addPlace')}
              </Button>
            </ListItem>
            <Pipe />
            <ListItem bold>
              <Button onClick={logoutHandler}>
                {t('navigationAdmin.logout')}
              </Button>
            </ListItem>
          </List>
        </OpenContainer>
      ) : (
        <img
          src={hamburger}
          width={33}
          height={33}
          alt="hamburger"
          onClick={openHamburger}
          loading="eager"
        />
      )}
    </HamburgerWrapper>
  );
};
