import errorIcon from '../../assets/icons/error.svg';
import { Error, ErrorContainer, StyledLabel } from './Inputs.styles';
import { Select, SelectChangeEvent } from '@mui/material';
import { useIsTablet } from '../../hooks/useIsTablet';
import { useIsWidescreen } from '../../hooks/useIsWidescreen';
import { useTranslation } from 'react-i18next';

interface DropdownProps {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  children: React.ReactNode;
  required?: boolean;
  error?: string;
  disabled?: boolean;
}

export const Dropdown = (props: DropdownProps) => {
  const { t } = useTranslation();

  const { label, value, onChange, children, required, error, disabled } = props;

  const isTablet = useIsTablet();
  const isWidescreen = useIsWidescreen();

  const selectWidth = isTablet
    ? '100%'
    : isWidescreen
    ? 'calc(75% + 1.4rem)'
    : 'calc(50% + 1.4rem)';

  return (
    <>
      <StyledLabel required={required}>{label}</StyledLabel>
      <Select
        onChange={onChange}
        value={value}
        style={{
          width: selectWidth,
        }}
        disabled={disabled}
        defaultValue={''}
      >
        {children}
      </Select>
      {error && (
        <ErrorContainer>
          <img src={errorIcon} alt="error" />
          <Error>{t(error)}</Error>
        </ErrorContainer>
      )}
    </>
  );
};
