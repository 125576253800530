import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MainContent } from '../../../components/grid/MainContent';
import { IconButton } from '../../../components/navigationPartner/buttons/IconButton';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { usePlace } from '../../../store/placeContext';

import { BiEdit } from 'react-icons/bi';
import { PartnerHomepageContent } from './PartnerHomepageContent';
import { PartnerHomepageEdit } from './PartnerHomepageEdit';
import { IconsContainer } from './PartnerHomepage.styles';
import { useTranslation } from 'react-i18next';
import { useFlags } from '../../../store/flagsContext';
import { useNavigate } from 'react-router';
import { Paths } from '../../../enums/Paths';
import { breakpoints } from '../../../styles/variables';

const MainFlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  gap: 1.5rem;
  position: relative;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`;

export const PartnerHomepage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state: place } = usePlace();
  const { state: flags } = useFlags();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (!flags.isLoading && !place.name) {
      navigate(Paths.partnerWelcome);
    }
  }, [flags, place]);

  if (flags.isLoading) {
    return (
      <MainContent hideCards>
        <LoadingSpinner />
      </MainContent>
    );
  } else if (place.name) {
    if (!isEditing) {
      return (
        <MainContent>
          <MainFlexContainer>
            <IconsContainer>
              <IconButton
                text={t('buttons.edit')}
                Icon={BiEdit}
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            </IconsContainer>
            <PartnerHomepageContent place={place} />
          </MainFlexContainer>
        </MainContent>
      );
    } else {
      return (
        <MainContent hideCards>
          <PartnerHomepageEdit onCancelEdit={() => setIsEditing(false)} />
        </MainContent>
      );
    }
  } else {
    return null;
  }
};
