import styled from 'styled-components';
import { breakpoints, colors } from '../../../styles/variables';

export const ImageContainer = styled.div`
  display: flex;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Label = styled.h5`
  font-size: 0.75rem;
  margin: 0 0 0.1rem;
`;

export const LabeledTextContainer = styled.div`
  margin-bottom: 0.3rem;
`;

export const Text = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.grey};
  :first-letter {
    text-transform: uppercase;
  }
`;

export const OuterFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  position: relative;
`;

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  @media (min-width: ${breakpoints.tablet}) {
    overflow: auto;
    height: 100%;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}) {
    gap: 1rem;
    flex-direction: row;
  }
`;
