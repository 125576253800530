import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { useIsTablet } from '../../hooks/useIsTablet';
import { Cards } from '../cards/Cards';
import { MobileCards } from '../cards/MobileCards';
import { MainContainer } from '../containers/MainContainer';

const GridItem = styled(Grid)`
  z-index: 2;
`;

interface MainContentProps {
  children: ReactNode;
  hideCards?: boolean;
}

export const MainContent = (props: MainContentProps) => {
  const { children, hideCards } = props;

  const isTablet = useIsTablet();

  return (
    <Grid container spacing={2} height="100%">
      <GridItem item xs={12} height={`${hideCards ? '100%' : '85%'}`}>
        <MainContainer>{children}</MainContainer>
      </GridItem>
      {hideCards ? null : isTablet ? <MobileCards /> : <Cards />}
    </Grid>
  );
};
