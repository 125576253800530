import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.h1`
  font-size: 2rem;
  font-weight: 400;
`;

export const Description = styled.h2`
  font-size: 1.2rem;
  font-weight: 300;
`;

export const StyledLink = styled(Link)`
  color: ${colors.secondary};
  font-size: 1.2rem;
  font-weight: 300;
`;
