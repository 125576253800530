import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { MainButton } from '../../../components/buttons/MainButton';
import { ModalConfirmation } from '../../../components/modals/ModalConfirmation';
import { Paths } from '../../../enums/Paths';
import { WithLoadingProps } from '../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../hocs/WithLoading';
import { useIsTablet } from '../../../hooks/useIsTablet';
import { Place } from '../../../interfaces/Places';
import { PlacesService } from '../../../services/placesService';
import { colors } from '../../../styles/variables';
import { scrollToElementById } from '../../../utils/ScrollToElementById';
import { ChangesDiff } from './components/ChangesDiff';
import { flattenStagedChanges } from './helpers/flattenStagedChanges';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Header = styled.h1`
  font-size: 1.3rem;
  margin: 1rem 0;
  font-weight: 600;
`;

const BtnContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const ReviewStagedChanges = (props: WithLoadingProps) => {
  const { setLoading } = props;

  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const isTablet = useIsTablet();

  const [place, setPlace] = useState<Place>();

  const [showAcceptChangesModal, setShowAcceptChangesModal] = useState(false);
  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      const data = await PlacesService.fetchPlace(id);

      setPlace(data);
    };

    fetchData();
  }, []);

  const acceptChanges = async () => {
    setLoading(true);

    if (id) await PlacesService.acceptStagedChanges(id);

    setLoading(false);
    navigate(Paths.adminStagedChanges);
  };

  const discardChanges = async () => {
    setLoading(true);

    if (id) await PlacesService.discardStagedChanges(id);

    setLoading(false);
    navigate(Paths.adminStagedChanges);
  };

  useEffect(() => {
    scrollToElementById('modal');
  }, [showAcceptChangesModal, showDiscardChangesModal]);

  return (
    <>
      {place?.stagedChanges && (
        <FlexContainer>
          <Header>
            {t('stagedChanges.changesInPlace')}: {place.name}
          </Header>
          <BtnContainer>
            <MainButton
              title={t('buttons.accept')}
              btnColor={colors.primary}
              onClick={() => setShowAcceptChangesModal(true)}
              small={!!isTablet}
            />
            <MainButton
              title={t('buttons.discard')}
              btnColor={colors.asteriksRed}
              onClick={() => setShowDiscardChangesModal(true)}
              small={!!isTablet}
            />
          </BtnContainer>
          <ChangesDiff
            place={flattenStagedChanges(place)}
            newPlace={flattenStagedChanges(place.stagedChanges)}
          />
        </FlexContainer>
      )}
      {showAcceptChangesModal && (
        <ModalConfirmation
          onAccept={acceptChanges}
          onClose={() => {
            setShowAcceptChangesModal(false);
          }}
          title={t('stagedChanges.accept')}
        />
      )}
      {showDiscardChangesModal && (
        <ModalConfirmation
          onAccept={discardChanges}
          onClose={() => {
            setShowDiscardChangesModal(false);
          }}
          title={t('stagedChanges.discard')}
        />
      )}
    </>
  );
};

export default withLoading(ReviewStagedChanges);
