import { GoogleAutocompleteDetails } from '../interfaces/AutocompleteDetails';
import { Place, PlaceDetails, PlaceDTO } from '../interfaces/Places';
import { Reviews } from '../interfaces/Reviews';
import api from './api';

const defaultLanguage = 'pl';

export class PlacesService {
  static async fetchPlaces(
    query: string,
  ): Promise<{ data: Place[]; count: number }> {
    const res = await api.get(`/places${query}`);

    return {
      data: res.data.places,
      count: res.data.count,
    };
  }

  static async fetchUnpublishedPlaces(query: string): Promise<{
    data: Place[];
    count: number;
  }> {
    const res = await api.get(`/places/unpublished${query}`);

    return {
      data: res.data.places,
      count: res.data.count,
    };
  }

  static async fetchPlacesWithStagedChanges(
    query: string,
  ): Promise<{ data: Place[]; count: number }> {
    const res = await api.get(`/places/staged-changes${query}`);

    return {
      data: res.data.places,
      count: res.data.count,
    };
  }

  static async fetchUnassignedPlaces(query: string): Promise<Place[]> {
    const res = await api.get(`/places/unassigned${query}`);

    return res.data;
  }

  static async acceptStagedChanges(placeId: string) {
    const res = await api.post(`/places/staged-changes/${placeId}/accept`);
    return res;
  }

  static async discardStagedChanges(placeId: string) {
    const res = await api.post(`/places/staged-changes/${placeId}/discard`);
    return res;
  }

  static async fetchPlace(id: string): Promise<Place> {
    const res = await api.get(`/places/${id}`);

    return res.data;
  }

  static async fetchPlaceReviews(id: string): Promise<Reviews> {
    const res = await api.get(`/places/${id}/reviews`);

    return res.data;
  }

  static async fetchPlaceDetails(id: string): Promise<PlaceDetails> {
    const res = await api.get(`/places/${id}/details?lang=${defaultLanguage}`);

    return res.data.result;
  }

  static async fetchPlaceAutocompleteDetails(
    id: string,
  ): Promise<GoogleAutocompleteDetails> {
    const res = await api.get(
      `/places/${id}/details/autocomplete?lang=${defaultLanguage}`,
    );

    return res.data.result;
  }

  static async publishPlace(placeId: string) {
    const res = await api.patch(`/places/${placeId}/publish`);

    return res.data;
  }

  static async unpublishPlace(placeId: string) {
    const res = await api.patch(`/places/${placeId}/unpublish`);

    return res.data;
  }

  static async addPlace(body: PlaceDTO) {
    const res = await api.post('/places', body);

    return res.data;
  }

  static async editPlace(placeId: string, body: PlaceDTO) {
    const res = await api.patch(`/places/${placeId}`, body);

    return res.data;
  }

  static async partnerAddPlace(body: PlaceDTO) {
    const res = await api.post('/places/partner', body);

    return res.data;
  }

  static async partnerEditPlace(placeId: string, body: PlaceDTO) {
    const res = await api.patch(`/places/partner/${placeId}`, body);

    return res.data;
  }

  static async deletePlace(placeId: string) {
    const res = await api.delete(`/places/${placeId}`);

    return res.data;
  }

  static async addImage(placeId: string, imageUrl: string) {
    const res = await api.put(`/places/${placeId}/image`, {
      image: imageUrl,
    });

    return res.data;
  }

  static async partnerAddImage(placeId: string, imageUrl: string) {
    const res = await api.put(`/places/partner/${placeId}/image`, {
      image: imageUrl,
    });

    return res.data;
  }

  static async deleteImage(placeId: string, image: string) {
    const res = await api.delete(`/places/${placeId}/image`, {
      data: {
        image,
      },
    });

    return res.data;
  }

  static async partnerDeleteImage(placeId: string, image: string) {
    const res = await api.delete(`/places/partner/${placeId}/image`, {
      data: {
        image,
      },
    });

    return res.data;
  }

  static async addAvatar(placeId: string, imageUrl: string) {
    const res = await api.put(`/places/${placeId}/avatar`, {
      avatar: imageUrl,
    });

    return res.data;
  }

  static async partnerAddAvatar(placeId: string, imageUrl: string) {
    const res = await api.put(`/places/partner/${placeId}/avatar`, {
      avatar: imageUrl,
    });

    return res.data;
  }

  static async toggleShowGoogleImages(placeId: string) {
    const res = await api.put(`/places/${placeId}/show-google-images`);

    return res.data;
  }

  static async getCategories() {
    const res = await api.get('/enum-types/categories');

    return res.data;
  }

  static async getCategoriesTranslations() {
    const res = await api.get('/enum-types/categories/translations');

    return res.data;
  }

  static async getFeatures() {
    const res = await api.get('/enum-types/features');

    return res.data;
  }

  static async getFeaturesByCategory(category: string) {
    const res = await api.get(`/places/features/${category}`);

    return res.data;
  }

  static async getFeaturesTranslations() {
    const res = await api.get('/enum-types/features/translations');

    return res.data;
  }

  static async getStates() {
    const res = await api.get('/enum-types/states');

    return res.data;
  }

  static async getStatesTranslations() {
    const res = await api.get('/enum-types/states/translations');

    return res.data;
  }

  static async uploadPlaces(file: File) {
    const data = new FormData();
    data.append('file', file);

    const res = await api.post('/places/upload', data);

    return res.data;
  }

  static async getAcceptedDogsTranslations() {
    const res = await api.get('/enum-types/accepted-dogs/translations');

    return res.data;
  }
}
