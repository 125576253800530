import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../buttons/MainButton';
import { Input } from '../inputs/Input';
import { ModalFailure } from '../modals/ModalFailure';
import { colors } from '../../styles/variables';
import {
  validateRegister,
  ValidateRegisterProps,
} from '../../pages/register/helpers/ValidateRegister';
import { AuthService } from '../../services/authService';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { ModalSuccess } from '../modals/ModalSuccess';
import { Paths } from '../../enums/Paths';

const LinkContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.secondary};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

export const RegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [status, setStatus] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });
  const [errors, setErrors] = useState<ValidateRegisterProps>({});

  const submitHandler = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    setErrors({});
    setStatus((prevState) => {
      return {
        ...prevState,
        isSending: true,
      };
    });

    const validateRegisterErrors = validateRegister({
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      phoneNumber,
    });

    if (Object.keys(validateRegisterErrors).length > 0) {
      setErrors(validateRegisterErrors);
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });

      return;
    }

    try {
      const user = {
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        phoneNumber,
      };

      await AuthService.registerPartner(user);

      setStatus((prevState) => {
        return {
          ...prevState,
          isSent: true,
          isSending: false,
        };
      });
    } catch (errAdmin) {
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });
    }
  };

  return (
    <>
      <FormContainer onSubmit={submitHandler}>
        <Input
          label={t('registerForm.email')}
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrors({ ...errors, email: undefined });
          }}
          required
          error={errors.email}
        />
        <Input
          label={t('registerForm.password')}
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrors({
              ...errors,
              password: undefined,
              confirmPassword: undefined,
            });
          }}
          required
          error={errors.password}
        />
        <Input
          label={t('registerForm.confirmPassword')}
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setErrors({
              ...errors,
              password: undefined,
              confirmPassword: undefined,
            });
          }}
          required
          error={errors.confirmPassword}
        />
        <Input
          label={t('registerForm.firstName')}
          type="text"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setErrors({ ...errors, firstName: undefined });
          }}
          required
          error={errors.firstName}
        />
        <Input
          label={t('registerForm.lastName')}
          type="text"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setErrors({ ...errors, lastName: undefined });
          }}
          required
          error={errors.lastName}
        />
        <Input
          label={t('registerForm.phoneNumber')}
          type="text"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            setErrors({ ...errors, phoneNumber: undefined });
          }}
          required
          error={errors.phoneNumber}
        />
        {status.isSent && (
          <ModalSuccess
            title={t('registerForm.modalSuccess')}
            onClose={() => navigate(Paths.login)}
          />
        )}
        {status.isError && (
          <ModalFailure
            onClose={() =>
              setStatus((prevState) => {
                return {
                  ...prevState,
                  isError: false,
                };
              })
            }
          />
        )}
        <ButtonContainer>
          <MainButton
            title={t('buttons.register')}
            type="submit"
            disabled={status.isSending}
          />
        </ButtonContainer>
        {status.isSending && <LoadingSpinner />}
        <LinkContainer>
          {t('registerForm.hasAccount')}
          <StyledLink to={Paths.login}>{t('registerForm.login')}</StyledLink>
        </LinkContainer>
      </FormContainer>
    </>
  );
};
