import styled from 'styled-components';
import { IconType } from 'react-icons';

interface Props {
  small?: boolean;
}

const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    width: ${(props) => (props.small ? '1rem' : '1.2rem')};
    height: ${(props) => (props.small ? '1rem' : '1.2rem')};
  }
`;

const Text = styled.p<Props>`
  font-weight: 500;
  font-size: ${(props) => (props.small ? '0.9rem' : '1.2rem')};
  margin: 0;
`;

interface IconButtonProps {
  Icon: IconType;
  onClick?: () => void;
  text: string;
  small?: boolean;
}

export const IconButton = (props: IconButtonProps) => {
  const { Icon, onClick, text, small } = props;

  return (
    <Container onClick={onClick} small={small}>
      <Icon />
      <Text small={small}>{text}</Text>
    </Container>
  );
};
