import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';
import errorIcon from '../../assets/icons/error.svg';
import { Error, ErrorContainer, StyledLabel } from './Inputs.styles';
import { useTranslation } from 'react-i18next';

interface StyledInputProps {
  isError?: boolean;
}

const StyledInput = styled.textarea<StyledInputProps>`
  background: ${colors.white};
  border: ${(props) =>
    props.isError ? `2px solid ${colors.error};` : '2px solid #d6d6d6;'};
  border-radius: 6px;
  padding: 0.6rem 0.7rem;
  align-items: center;
  width: 90%;
  min-height: 5rem;
  resize: none;

  @media (min-width: ${breakpoints.tablet}) {
    width: 75%;
  }

  @media (min-width: ${breakpoints.widescreen}) {
    width: 50%;
  }
`;

interface TextareaProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  error?: string;
}

export const Textarea = (props: TextareaProps) => {
  const { t } = useTranslation();

  const { label, value, onChange, required, error } = props;

  return (
    <>
      <StyledLabel required={required}>{label}</StyledLabel>
      <StyledInput value={value} onChange={onChange} isError={!!error} />
      {error && (
        <ErrorContainer>
          <img src={errorIcon} alt="error" />
          <Error>{t(error)}</Error>
        </ErrorContainer>
      )}
    </>
  );
};
