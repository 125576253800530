import { useTranslation } from 'react-i18next';
import { PlaceForm } from '../../../components/forms/PlaceForm';
import { Header } from '../../../components/header/Header';
import { FormTypes } from '../../../interfaces/FormTypes';

export const EditPlace = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header>{t('placeForm.titleEdit')}</Header>
      <PlaceForm type={FormTypes.edit} />
    </>
  );
};
