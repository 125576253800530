import { useTranslation } from 'react-i18next';
import { Paths } from '../../../enums/Paths';
import * as S from '../ActivateAccount.Styles';

export const ActivationSuccess = () => {
  const { t } = useTranslation();

  return (
    <S.MainContainer>
      <S.Header>{t('activateAccount.success')}</S.Header>
      <S.Description>
        {t('activateAccount.successPrimaryDescription')}
      </S.Description>
      <S.Description>
        {t('activateAccount.successSecondaryDescription')}
      </S.Description>
      <S.StyledLink to={Paths.login}>
        {t('activateAccount.backToLogin')}
      </S.StyledLink>
    </S.MainContainer>
  );
};
