import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReviewCell } from '../../../interfaces/ReviewCell';

const Name = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
  white-space: nowrap;
`;

export const reviewsColumns = () => {
  const { t } = useTranslation();

  return [
    {
      Header: t('columns.reviews.placeName'),
      Cell: ({ row }: ReviewCell) => (
        <>
          <Name>{row.original.placeId.name}</Name>
        </>
      ),
    },
    {
      Header: t('columns.reviews.userName'),
      Cell: ({ row }: ReviewCell) => (
        <>
          <Name>{row.original.userId.firstName}</Name>
        </>
      ),
    },
    {
      Header: t('columns.reviews.comment'),
      id: 'comment',
      accessor: 'comment',
    },
  ];
};
