import React, { useState } from 'react';
import { LoadingSpinner } from '../components/spinner/LoadingSpinner';

export const withLoading = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const HOC = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);

    const setLoading = (loading: boolean) => setIsLoading(loading);

    return (
      <>
        {isLoading && <LoadingSpinner />}
        <WrappedComponent
          {...props}
          setLoading={setLoading}
          loading={isLoading}
        />
      </>
    );
  };

  return HOC;
};
