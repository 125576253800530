import * as React from 'react';

interface IFlagsContext {
  isLoading: boolean;
  isPlaceAdded: boolean;
}

const defaultValue: IFlagsContext = {
  isLoading: true,
  isPlaceAdded: false,
};

export enum FlagsContextTypes {
  setLoading = 'SET_LOADING',
  setPlaceAdded = 'SET_PLACE_ADDED',
}

interface Action {
  type: FlagsContextTypes;
  payload: boolean;
}
type Dispatch = (action: Action) => void;

const FlagsContext = React.createContext<
  { state: IFlagsContext; dispatch: Dispatch } | undefined
>(undefined);

function flagsReducer(state: IFlagsContext, action: Action) {
  switch (action.type) {
    case FlagsContextTypes.setLoading: {
      return { ...state, isLoading: action.payload };
    }
    case FlagsContextTypes.setPlaceAdded: {
      return { ...state, isPlaceAdded: action.payload };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

interface FlagsProviderProps {
  children: React.ReactNode;
}

const FlagsProvider = (props: FlagsProviderProps) => {
  const [state, dispatch] = React.useReducer(flagsReducer, defaultValue);

  const value = { state, dispatch };

  return (
    <FlagsContext.Provider value={value}>
      {props.children}
    </FlagsContext.Provider>
  );
};

function useFlags() {
  const context = React.useContext(FlagsContext);
  if (context === undefined) {
    throw new Error('useFlags must be used within a FlagsProvider');
  }
  return context;
}

export { FlagsProvider, useFlags };
