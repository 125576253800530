import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../../styles/variables';

export const StyledIconButton = styled(IconButton)`
  && {
    background-color: ${colors.secondary};

    :hover {
      background-color: ${colors.secondary};
    }
  }
  svg {
    color: ${colors.white};
  }
`;
