import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PlaceForm } from '../../../components/forms/PlaceForm';
import { Header } from '../../../components/header/Header';
import { CsvInput } from '../../../components/inputs/CsvInput';
import { ModalFailure } from '../../../components/modals/ModalFailure';
import { ModalSuccess } from '../../../components/modals/ModalSuccess';
import { FormTypes } from '../../../interfaces/FormTypes';
import { PlacesService } from '../../../services/placesService';
import { scrollToElementById } from '../../../utils/ScrollToElementById';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const AddPlace = () => {
  const { t } = useTranslation();

  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const uploadPlaces = async (file: File) => {
    try {
      await PlacesService.uploadPlaces(file);
      setIsSent(true);
    } catch (err) {
      setIsError(true);
    }
  };

  useEffect(() => {
    scrollToElementById('modal');
  }, [isSent, isError]);

  return (
    <>
      <FlexContainer>
        <Header>{t('placeForm.titleAdd')}</Header>
        <CsvInput
          onChange={(e) => {
            const { files } = e.target;
            if (files) uploadPlaces(files[0]);
          }}
        />
      </FlexContainer>
      <PlaceForm type={FormTypes.add} />
      {isSent && (
        <ModalSuccess
          onClose={() => setIsSent(false)}
          title={t('csv.modalSuccess')}
        />
      )}
      {isError && <ModalFailure onClose={() => setIsError(false)} />}
    </>
  );
};
