import { Review } from '../interfaces/Reviews';
import api from './api';

export class ReviewsService {
  static async fetchReportedPlaceReviews(
    query: string,
  ): Promise<{ data: Review[]; count: number }> {
    const res = await api.get(`/reviews/reported${query}`);

    return {
      data: res.data.reviews,
      count: res.data.count,
    };
  }

  static async reportReview(placeId: string, id: string) {
    const res = await api.post(`/places/${placeId}/review/${id}/report`);

    return res.data;
  }

  static async restoreReview(placeId: string, id: string) {
    const res = await api.post(`/places/${placeId}/review/${id}/restore`);

    return res.data;
  }

  static async deleteReview(placeId: string, id: string) {
    const res = await api.delete(`/places/${placeId}/review/${id}`);

    return res.data;
  }
}
