import { isValidHttpUrl } from '../../../utils/IsValidHttpUrl';

export interface ValidatePlaceFormProps {
  category?: string;
  state?: string;
  name?: string;
  city?: string;
  street?: string;
  zip?: string;
  phoneNumber?: string;
  email?: string;
  webPage?: string;
  isAddressOptional?: boolean;
}

export const ValidatePlaceForm = (values: ValidatePlaceFormProps) => {
  const errors: ValidatePlaceFormProps = {};

  if (!values.category) {
    errors.category = 'placeValidation.category';
  }
  if (!values.name) {
    errors.name = 'placeValidation.name';
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'placeValidation.email';
  }
  if (
    values.phoneNumber &&
    !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g.test(values.phoneNumber)
  ) {
    errors.phoneNumber = 'placeValidation.phone';
  }
  if (values.webPage && !isValidHttpUrl(values.webPage)) {
    errors.webPage = 'placeValidation.webPage';
  }

  if (values.isAddressOptional) {
    return errors;
  }

  if (!values.state) {
    errors.state = 'placeValidation.state';
  }
  if (!values.city) {
    errors.city = 'placeValidation.city';
  }
  if (!values.street) {
    errors.street = 'placeValidation.street';
  }
  if (!values.zip) {
    errors.zip = 'placeValidation.zip';
  }

  return errors;
};
