import React from 'react';
import styled from 'styled-components';
import { useIsTablet } from '../../../hooks/useIsTablet';
import { breakpoints } from '../../../styles/variables';
import { Header } from '../../header/Header';
import { Pagination } from './pagination/Pagination';
import { SearchBar } from './search/SearchBar';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    gap: 2rem;
  }
`;

interface TableOptionsProps {
  paginationProps: {
    currentPage: number;
    pageCount: number;
    handlePageClick: (e: { selected: number }) => void;
  };
  searchBarProps: {
    value: string;
    handleSetValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSearch: () => void;
  };
  header: string;
}

export const TableOptions = (props: TableOptionsProps) => {
  const { paginationProps, searchBarProps, header } = props;

  const isTablet = useIsTablet();

  if (isTablet) {
    return (
      <Container>
        <Header>{header}</Header>
        <SearchBar
          value={searchBarProps.value}
          handleSetValue={searchBarProps.handleSetValue}
          handleSearch={searchBarProps.handleSearch}
        />
        <Pagination
          currentPage={paginationProps.currentPage}
          pageCount={paginationProps.pageCount}
          handlePageClick={paginationProps.handlePageClick}
        />
      </Container>
    );
  } else if (!isTablet) {
    return (
      <Container>
        <SearchBar
          value={searchBarProps.value}
          handleSetValue={searchBarProps.handleSetValue}
          handleSearch={searchBarProps.handleSearch}
        />
        <Header>{header}</Header>
        <Pagination
          currentPage={paginationProps.currentPage}
          pageCount={paginationProps.pageCount}
          handlePageClick={paginationProps.handlePageClick}
        />
      </Container>
    );
  } else return null;
};
