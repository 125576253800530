import { ReactNode } from 'react';
import { useRole } from '../../hooks/useRole';
import { UserRolesEnum } from '../../enums/UserRolesEnum';
import { Navbar } from '../navigation/Navbar';
import PartnerLayout from './PartnerLayout';
import { Wrapper } from './Layout.styles';

interface Props {
  children: ReactNode;
}

const MainLayout = (props: Props) => {
  const { children } = props;

  const role = useRole();

  if (role === UserRolesEnum.Partner) {
    return <PartnerLayout>{children}</PartnerLayout>;
  } else {
    return (
      <>
        <Navbar />
        <Wrapper>{children}</Wrapper>
      </>
    );
  }
};

export default MainLayout;
