export interface ValidateRegisterProps {
  email?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export const validateRegister = (values: ValidateRegisterProps) => {
  const errors: ValidateRegisterProps = {};

  if (!values.email) {
    errors.email = 'registerValidation.email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'loginValidation.incorrectEmail';
  }
  if (!values.password) {
    errors.password = 'registerValidation.password';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'registerValidation.confirmPassword';
  }
  if (
    values.password &&
    values.confirmPassword &&
    values.password !== values.confirmPassword
  ) {
    errors.password = 'registerValidation.passwordsDoesNotMatch';
    errors.confirmPassword = 'registerValidation.passwordsDoesNotMatch';
  }
  if (!values.firstName) {
    errors.firstName = 'registerValidation.firstName';
  }
  if (!values.lastName) {
    errors.lastName = 'registerValidation.lastName';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'registerValidation.phoneNumber';
  }

  return errors;
};
