import { Box, TextField } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Place } from '../../../interfaces/Places';
import { PlacesService } from '../../../services/placesService';
import { breakpoints } from '../../../styles/variables';
import { StyledLabel } from '../../inputs/Inputs.styles';
import AutocompleteMui, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  text-align: left;
`;

const AutocompleteContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 80%;
  }
`;

interface AssignToPlaceButtonProps {
  selectedPlaces: string[];
  setSelectedPlaces: Dispatch<SetStateAction<string[]>>;
}

export const AssignToPlaceButton = (props: AssignToPlaceButtonProps) => {
  const { t } = useTranslation();

  const { setSelectedPlaces } = props;

  const [unassignedPlaces, setUnassignedPlaces] = useState<Place[]>([]);

  const fetchPlaces = async () => {
    const places = await PlacesService.fetchUnassignedPlaces(
      '?sort=-createdAt',
    );
    setUnassignedPlaces(places);
  };

  useEffect(() => {
    fetchPlaces();
  }, []);

  const onSelect = async (
    _: SyntheticEvent<Element, Event>,
    val: Place[] | null,
  ) => {
    if (val) {
      setSelectedPlaces(val.map((item) => item._id));
    }
  };

  return (
    <Container>
      <StyledLabel>
        {t('registerForm.unassignedPlaces')} ({unassignedPlaces.length})
      </StyledLabel>
      <AutocompleteContainer>
        <AutocompleteMui
          multiple
          options={unassignedPlaces}
          getOptionLabel={(option) => option.name}
          renderOption={(optionProps, option) => (
            <Box component="li" {...optionProps} key={option._id}>
              {option.name}
            </Box>
          )}
          fullWidth
          onChange={onSelect}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              type="text"
              placeholder={t('search')}
              fullWidth
            />
          )}
        />
      </AutocompleteContainer>
    </Container>
  );
};
