import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: 500;
  line-height: 1.8rem;
  padding-top: 0.6rem;

  color: ${colors.white};
  border-radius: 30px;
  text-align: center;
  width: 90%;

  background-color: #f1b016;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-bottom: 1rem;

  input {
    display: none;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 10rem;
  }
`;

interface FileInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CsvInput = (props: FileInputProps) => {
  const { t } = useTranslation();

  const { onChange } = props;

  const addFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    onChange(e);
  };

  return (
    <Container>
      <Label>
        {t('csv.title')}
        <input type="file" onChange={addFileHandler} accept=".csv" />
      </Label>
    </Container>
  );
};
