import { useReviews } from '../../store/reviewsContext';
import { CardContainer } from '../containers/CardContainer';
import { CardText } from '../containers/CardContainer.styles';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { usePlace } from '../../store/placeContext';
import { useTranslation } from 'react-i18next';
import { formatNum } from '../../utils/FormatNumber';

export const GridItem = styled(Grid)`
  z-index: 2;
  height: 14%;
`;

export const Cards = () => {
  const { t } = useTranslation();

  const { state: reviews } = useReviews();
  const { state: place } = usePlace();

  const cardSize = 3;

  if (reviews) {
    return (
      <>
        <GridItem flexGrow={1} item xs={cardSize}>
          <CardContainer title={t('cards.visitors')}>
            <CardText>{place.numberOfVisitors ?? 0}</CardText>
          </CardContainer>
        </GridItem>
        <GridItem flexGrow={1} item xs={cardSize}>
          <CardContainer title={t('cards.published')}>
            <CardText>{place.isPublished ? t('yes') : t('no')}</CardText>
          </CardContainer>
        </GridItem>
        <GridItem flexGrow={1} item xs={cardSize}>
          <CardContainer title={t('cards.googleRating')}>
            <CardText>
              {place.details?.rating
                ? `${formatNum(place.details?.rating, 2)}/5`
                : t('cards.noRatings')}
            </CardText>
          </CardContainer>
        </GridItem>
        <GridItem flexGrow={1} item xs={cardSize}>
          <CardContainer title={t('cards.appRating')}>
            <CardText>
              {reviews.averageRating
                ? `${formatNum(reviews.averageRating, 2)}/5`
                : t('cards.noRatings')}
            </CardText>
          </CardContainer>
        </GridItem>
      </>
    );
  } else {
    return null;
  }
};
