import { ReactNode } from 'react';
import { FlagsProvider } from './flagsContext';
import { PlaceProvider } from './placeContext';
import { ReviewsProvider } from './reviewsContext';

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider = (props: AppProviderProps) => {
  const { children } = props;

  return (
    <FlagsProvider>
      <PlaceProvider>
        <ReviewsProvider>{children}</ReviewsProvider>
      </PlaceProvider>
    </FlagsProvider>
  );
};
