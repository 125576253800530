import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PlaceDTO } from '../../interfaces/Places';
import { PlacesService } from '../../services/placesService';
import { MainButton } from '../buttons/MainButton';
import { Dropdown } from '../inputs/Dropdown';
import { FileInput } from '../inputs/FileInput';
import { Input } from '../inputs/Input';
import { ModalFailure } from '../modals/ModalFailure';
import { ModalSuccess } from '../modals/ModalSuccess';
import {
  ValidatePlaceForm,
  ValidatePlaceFormProps,
} from './validators/ValidatePlaceForm';
import { Textarea } from '../inputs/Textarea';
import { AcceptedDogsEnum } from '../../enums/AcceptedDogsEnum';
import { AcceptedDog } from './AcceptedDog';
import { scrollToElementById } from '../../utils/ScrollToElementById';
import { uploadImages } from '../../utils/UploadImages';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { ObjectType } from '../../interfaces/ObjectType';
import { FormTypes } from '../../interfaces/FormTypes';
import { ImageSender } from '../../interfaces/ImageSender';
import { SelectFeatureButton } from '../navigationPartner/buttons/SelectFeatureButton';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { Paths } from '../../enums/Paths';
import * as S from './Forms.Styles';
import { Autocomplete } from '../autocomplete/Autocomplete';

interface PlaceFormProps {
  type: FormTypes;
}

export const PlaceForm = (props: PlaceFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  const { type } = props;

  const [categories, setCategories] = useState([]);
  const [categoriesTranslations, setCategoriesTranslations] =
    useState<ObjectType>();
  const [states, setStates] = useState<string[]>([]);
  const [statesTranslations, setStatesTranslations] = useState<ObjectType>();

  const [isAddressOptional, setIsAddressOptional] = useState(false);

  const [category, setCategory] = useState('other');
  const [state, setState] = useState('mazowieckie');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [zip, setZip] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [webPage, setWebPage] = useState('');
  const [selectedPetFeatures, setSelectedPetFeatures] = useState<string[]>([]);
  const [googleId, setGoogleId] = useState('');
  const [acceptedDogs, setAcceptedDogs] = useState<AcceptedDogsEnum[]>([]);
  const [avatar, setAvatar] = useState<FileList>();
  const [images, setImages] = useState<FileList>();

  useEffect(() => {
    const fetchTypes = async () => {
      const categoriesRes = await PlacesService.getCategories();
      const categoriesTranslationsRes =
        await PlacesService.getCategoriesTranslations();
      const statesRes = await PlacesService.getStates();
      const statesTranslationsRes = await PlacesService.getStatesTranslations();

      setCategories(categoriesRes);
      setCategoriesTranslations(categoriesTranslationsRes);
      setStates(statesRes);
      setStatesTranslations(statesTranslationsRes);
    };

    fetchTypes();
  }, []);

  useEffect(() => {
    if (type === FormTypes.add || !id) return;

    const fetchData = async () => {
      const data = await PlacesService.fetchPlace(id);

      setIsAddressOptional(!data.address);
      setCategory(data.category ?? 'other');
      setState(data.address?.state ?? 'mazowieckie');
      setName(data.name);
      setCity(data.address?.city);
      setStreet(data.address?.street);
      setZip(data.address?.zip);
      setAcceptedDogs(data.acceptedDogs);
      setPhoneNumber(data.phoneNumber);
      setEmail(data.email);
      setDescription(data.description);
      setWebPage(data.webPage);
      setSelectedPetFeatures(data.petFeatures);
      setGoogleId(data.googleId ?? '');
    };

    fetchData();
  }, []);

  const [status, setStatus] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });
  const [errors, setErrors] = useState<ValidatePlaceFormProps>({});

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrors({});
    setStatus((prevState) => {
      return {
        ...prevState,
        isSending: true,
      };
    });

    const validateErrors = ValidatePlaceForm({
      category,
      state,
      name,
      city,
      street,
      zip,
      phoneNumber,
      email,
      webPage,
      isAddressOptional,
    });

    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });

      return;
    }

    const sendData = async () => {
      try {
        const dataToSend: PlaceDTO = {
          name,
          category,
          description,
          webPage,
          petFeatures: selectedPetFeatures,
          phoneNumber,
          email,
          acceptedDogs,
          googleId,
        };
        if (!isAddressOptional) {
          dataToSend.address = {
            city,
            street,
            zip,
            state,
            country: 'PL',
          };
        }

        let placeId = id;

        if (type === FormTypes.edit && placeId) {
          await PlacesService.editPlace(placeId, dataToSend);
        } else {
          const response = await PlacesService.addPlace(dataToSend);
          placeId = response._id;
        }

        await uploadImages({
          avatar,
          images,
          placeId,
          sender: ImageSender.admin,
        });

        setStatus((prevState) => {
          return {
            ...prevState,
            isSent: true,
            isSending: false,
          };
        });
      } catch (error) {
        setStatus((prevState) => {
          return {
            ...prevState,
            isError: true,
            isSending: false,
          };
        });
      }
    };

    sendData();
  };

  useEffect(() => {
    scrollToElementById('spinner');
    scrollToElementById('modal');
  }, [status]);

  if (categories.length === 0 || states.length === 0) {
    return <LoadingSpinner />;
  } else {
    return (
      <S.FormWrapper>
        <S.FormContainer onSubmit={submitHandler}>
          <Autocomplete
            statesTranslations={statesTranslations}
            setCity={setCity}
            setDescription={setDescription}
            setName={setName}
            setPhoneNumber={setPhoneNumber}
            setState={setState}
            setStreet={setStreet}
            setWebPage={setWebPage}
            setZip={setZip}
            setGoogleId={setGoogleId}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isAddressOptional}
                onChange={() => setIsAddressOptional((prevState) => !prevState)}
              />
            }
            label={t('placeForm.isAddressOptional')}
          />
          <Dropdown
            label={t('place.category')}
            onChange={(e) => {
              setCategory(e.target.value);
              setSelectedPetFeatures([]);
              setErrors({ ...errors, category: undefined });
            }}
            required
            value={category}
            error={errors.category}
          >
            {categories.map((item: string, idx: number) => (
              <MenuItem key={idx} value={item}>
                {categoriesTranslations ? categoriesTranslations[item] : item}
              </MenuItem>
            ))}
          </Dropdown>
          <Input
            label={t('place.name')}
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors({ ...errors, name: undefined });
            }}
            required
            error={errors.name}
          />
          <Dropdown
            label={t('place.state')}
            onChange={(e) => {
              setState(e.target.value);
              setErrors({ ...errors, state: undefined });
            }}
            required={!isAddressOptional}
            value={state}
            error={errors.state}
            disabled={isAddressOptional}
          >
            {states.map((item: string, idx: number) => (
              <MenuItem key={idx} value={item}>
                {statesTranslations ? statesTranslations[item] : item}
              </MenuItem>
            ))}
          </Dropdown>
          <Input
            label={t('place.city')}
            type="text"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              setErrors({ ...errors, city: undefined });
            }}
            required={!isAddressOptional}
            error={errors.city}
            disabled={isAddressOptional}
          />
          <Input
            label={t('place.zip')}
            type="text"
            value={zip}
            onChange={(e) => {
              setZip(e.target.value);
              setErrors({ ...errors, zip: undefined });
            }}
            required={!isAddressOptional}
            error={errors.zip}
            disabled={isAddressOptional}
          />
          <Input
            label={t('place.street')}
            type="text"
            value={street}
            onChange={(e) => {
              setStreet(e.target.value);
              setErrors({ ...errors, street: undefined });
            }}
            required={!isAddressOptional}
            error={errors.street}
            disabled={isAddressOptional}
          />
          <Input
            label={t('place.phoneNumber')}
            type="text"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setErrors({ ...errors, phoneNumber: undefined });
            }}
            error={errors.phoneNumber}
          />
          <Input
            label={t('place.email')}
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors({ ...errors, email: undefined });
            }}
            error={errors.email}
          />
          <Input
            label={t('place.googleId')}
            type="text"
            value={googleId}
            onChange={(e) => {
              setGoogleId(e.target.value);
            }}
          />
          <Input
            label={t('place.webPage')}
            type="text"
            value={webPage}
            onChange={(e) => {
              setWebPage(e.target.value);
              setErrors({ ...errors, webPage: undefined });
            }}
            error={errors.webPage}
          />
          <Textarea
            label={t('place.description')}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <SelectFeatureButton
            selectedPetFeatures={selectedPetFeatures}
            setSelectedPetFeatures={setSelectedPetFeatures}
            category={category}
          />
          <AcceptedDog
            acceptedDogs={acceptedDogs}
            setAcceptedDogs={setAcceptedDogs}
          />
          <p>Maksymalny rozmiar pliku to 4MB</p>
          <FileInput
            label={t('place.avatar')}
            onChange={(e) => {
              const { files } = e.target;
              if (files) setAvatar(files);
            }}
            multiple={false}
          />
          <FileInput
            label={t('place.images')}
            onChange={(e) => {
              const { files } = e.target;
              if (files) setImages(files);
            }}
            multiple={true}
          />
          {status.isSent && (
            <ModalSuccess
              onClose={() => {
                setStatus((prevState) => {
                  return {
                    ...prevState,
                    isSent: false,
                  };
                });
                navigate(Paths.admin);
              }}
              title={t([
                `placeForm.${
                  type === FormTypes.edit ? 'modalEdit' : 'modalAdd'
                }`,
              ])}
            />
          )}
          {status.isError && (
            <ModalFailure
              onClose={() =>
                setStatus((prevState) => {
                  return {
                    ...prevState,
                    isError: false,
                  };
                })
              }
            />
          )}
          <S.ButtonWrapper>
            <MainButton
              title={t([`buttons.${type === FormTypes.edit ? 'save' : 'add'}`])}
              type={'submit'}
              disabled={status.isSending}
            />
          </S.ButtonWrapper>
          {status.isSending && <LoadingSpinner />}
        </S.FormContainer>
      </S.FormWrapper>
    );
  }
};
