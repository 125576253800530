import { useEffect, useState } from 'react';
import { Table } from '../../../components/table/Table';
import { baseColumns } from '../../../components/table/columns/TableBaseColumns';
import { PlacesService } from '../../../services/placesService';
import {
  FlattenedPlace,
  flattenPlaces,
} from '../unpublished/helpers/flattenPlaces';
import { ButtonColumn } from '../../../components/table/columns/ButtonColumn';
import { itemsPerPage } from '../../../styles/variables';
import { TableOptions } from '../../../components/table/tableOptions/TableOptions';
import { BiShow } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../../enums/Paths';
import { NoApplication } from '../../../components/noApplication/NoApplication';
import { WithLoadingProps } from '../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../hocs/WithLoading';
import { showTableOptions } from '../../../utils/ShowTableOptions';

const StagedChanges = (props: WithLoadingProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setLoading } = props;

  const [places, setPlaces] = useState<FlattenedPlace[]>([]);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchData = async () => {
    setLoading(true);

    const query = `?page=${
      currentPage + 1
    }&limit=${itemsPerPage}&search=${search}&sort=-createdAt`;

    const { data, count } = await PlacesService.fetchPlacesWithStagedChanges(
      query,
    );

    setPageCount(Math.ceil(count / itemsPerPage));

    setPlaces(flattenPlaces(data));

    setLoading(false);
  };

  const handleSearch = async () => {
    setCurrentPage(0);
    fetchData();
  };

  const showChangesClickHandler = (place: FlattenedPlace) => {
    navigate(Paths.adminStagedChangesItem(place.id));
  };

  const columns = [
    ...baseColumns(),
    ButtonColumn({
      id: 'button_1',
      onClick: showChangesClickHandler,
      title: t('columns.buttons.showChanges'),
      icon: <BiShow />,
    }),
  ];

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <>
      {showTableOptions(pageCount, search) && (
        <TableOptions
          paginationProps={{
            currentPage,
            pageCount,
            handlePageClick: async (e) => {
              setCurrentPage(e.selected);
            },
          }}
          searchBarProps={{
            value: search,
            handleSetValue: (e) => setSearch(e.target.value),
            handleSearch,
          }}
          header={t('stagedChanges.title')}
        />
      )}
      {places && places.length > 0 ? (
        <Table columns={columns} data={places} />
      ) : (
        <NoApplication />
      )}
    </>
  );
};

export default withLoading(StagedChanges);
