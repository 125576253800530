import { ImageList, ImageListItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineCancel } from 'react-icons/md';
import styled from 'styled-components';
import { ModalConfirmation } from '../../../../components/modals/ModalConfirmation';
import { useIsTablet } from '../../../../hooks/useIsTablet';
import { useRefetchPlace } from '../../../../hooks/useRefetchPlace';
import { GoogleImage } from '../../../../interfaces/Places';
import { GoogleService } from '../../../../services/googleService';
import { PlacesService } from '../../../../services/placesService';
import { colors } from '../../../../styles/variables';
import { Text } from '../../homepage/PartnerHomepage.styles';
import { ImageAttribute, ImageContainer } from '../PartnerImages.styles';

const Img = styled.img`
  height: 10rem;
  width: 100%;
  object-fit: contain;
  cursor: default;
`;

const ImgList = styled(ImageList)`
  margin: 1rem 0 0;
  && {
    padding-right: 1rem;
  }
`;

const Delete = styled(MdOutlineCancel)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${colors.secondary};
  cursor: pointer;
`;

interface ImageListSectionProps {
  images: string[];
  googleImages?: GoogleImage[];
  placeId: string;
  setIsLoading: (value: boolean) => void;
  showGoogleImages: boolean;
}

export const ImageListSection = (props: ImageListSectionProps) => {
  const { t } = useTranslation();
  const isTablet = useIsTablet();

  const { images, placeId, setIsLoading, showGoogleImages } = props;

  const refetchPlace = useRefetchPlace();

  const [imageToDelete, setImageToDelete] = useState('');
  const [downloadedGoogleImages, setDownloadedGoogleImages] = useState<
    string[]
  >([]);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchedImages = await GoogleService.getGoogleImages(placeId);

      setDownloadedGoogleImages(fetchedImages);
    };

    fetchImages();
  }, [placeId]);

  const deleteImageHandler = async (image: string) => {
    if (placeId) {
      setIsLoading(true);

      await PlacesService.partnerDeleteImage(placeId, image);

      await refetchPlace(placeId);

      setIsLoading(false);
    }
    setImageToDelete('');
  };

  const renderGoogleImages = () => {
    if (downloadedGoogleImages?.length > 0) {
      return downloadedGoogleImages.map((item, idx: number) => (
        <ImageListItem key={idx}>
          <ImageContainer>
            <Img src={item} key={idx} />
          </ImageContainer>
        </ImageListItem>
      ));
    } else {
      return <Text>{t('images.noImages')}</Text>;
    }
  };

  const renderPartnerImages = () => {
    return images.map((item: string, idx: number) => (
      <ImageListItem key={idx}>
        <ImageContainer>
          <Img src={item} key={idx} />
          <ImageAttribute onClick={() => setImageToDelete(item)}>
            <Delete />
          </ImageAttribute>
        </ImageContainer>
      </ImageListItem>
    ));
  };

  return (
    <>
      <ImgList cols={isTablet ? 2 : 4} rowHeight={164} gap={16}>
        {!showGoogleImages && images?.length ? (
          renderPartnerImages()
        ) : showGoogleImages && downloadedGoogleImages.length ? (
          renderGoogleImages()
        ) : (
          <Text>{t('images.noImages')}</Text>
        )}
      </ImgList>
      {imageToDelete && (
        <ModalConfirmation
          title={t('images.modalConfirmDelete')}
          onAccept={deleteImageHandler.bind(null, imageToDelete)}
          onClose={() => setImageToDelete('')}
        />
      )}
    </>
  );
};
