import modalFailureImg from '../../assets/images/modalFailureImg.png';
import modalClose from '../../assets/icons/modalClose.svg';
import { CloseModal, Container, Wrapper } from './Modals.styles';
import { useTranslation } from 'react-i18next';

interface ModalSuccessProps {
  onClose: () => void;
}

export const ModalFailure = (props: ModalSuccessProps) => {
  const { t } = useTranslation();

  const { onClose } = props;

  return (
    <Wrapper>
      <Container id="modal">
        <CloseModal onClick={onClose}>
          <img src={modalClose} alt="modal-close" />
        </CloseModal>
        <img src={modalFailureImg} alt="modal-success" />
        <p>{t('modals.failure')}</p>
      </Container>
    </Wrapper>
  );
};
