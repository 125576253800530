export const breakpoints = {
  uhd: '1980px',
  widescreen: '1366px',
  desktop: '1024px',
  tablet: '768px',
};

export const breakpointsNum = {
  uhd: 1980,
  widescreen: 1366,
  desktop: 1024,
  tablet: 768,
};

export const colors = {
  primary: '#F0AF15',
  secondary: '#007D96',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#9FA2B4',
  doggieDark: '#004554',
  asteriksRed: '#FA3A3A',
  lightRed: '#FF7177',
  error: '#FA3A3A',
};

export const itemsPerPage = 15;
