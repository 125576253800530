import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '../../../../styles/variables';

const Container = styled.div``;

const Header = styled.h1`
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0 0 0.5rem;
`;

const Description = styled.h2`
  font-weight: 500;
  color: ${colors.grey};
  margin: 0;
`;

export const TitleSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('settings.title')}</Header>
      <Description>{t('settings.description')}</Description>
    </Container>
  );
};
