import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useActivePage = () => {
  const location = useLocation();

  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    setActivePage(`/${location.pathname.split('/')[1]}`);
  }, [location]);

  return activePage;
};
