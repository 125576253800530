import { useEffect, useState } from 'react';
import { BiCheck, BiEdit, BiTrash } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { ModalConfirmation } from '../../../components/modals/ModalConfirmation';
import { partnersColumns } from '../../../components/table/columns/TablePartnersColumns';
import { PartnerUser, User } from '../../../interfaces/User';
import { UserService } from '../../../services/userService';
import { PartnerButtonColumn } from '../../../components/table/columns/PartnerButtonColumn';
import { PartnersTable } from '../../../components/table/PartnersTable';
import { Header } from '../../../components/header/Header';
import { ModalEditPartnerPlace } from '../../../components/modals/ModalEditPartnerPlace';
import { withLoading } from '../../../hocs/WithLoading';
import { WithLoadingProps } from '../../../hocs/interfaces/WithLoadingProps';
import { NoApplication } from '../../../components/noApplication/NoApplication';

const Partners = (props: WithLoadingProps) => {
  const { setLoading } = props;

  const { t } = useTranslation();

  const [newPartners, setNewPartners] = useState<PartnerUser[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<PartnerUser>();

  const [showConfirmAccept, setShowConfirmAccept] = useState(false);
  const [showEditPartnerPlacesModal, setShowEditPartnerPlacesModal] =
    useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const showAcceptPartner = (user: PartnerUser) => {
    setSelectedPartner(user);
    setShowConfirmAccept(true);
  };

  const showEditPartnerPlaces = (user: PartnerUser) => {
    setSelectedPartner(user);
    setShowEditPartnerPlacesModal(true);
  };

  const showDeletePartner = (user: PartnerUser) => {
    setSelectedPartner(user);
    setShowConfirmDelete(true);
  };

  const columns = [
    ...partnersColumns(),
    PartnerButtonColumn({
      id: 'button_1',
      onClick: showAcceptPartner,
      title: t('columns.buttons.accept'),
      icon: <BiCheck />,
    }),
    PartnerButtonColumn({
      id: 'button_2',
      onClick: showEditPartnerPlaces,
      title: t('columns.buttons.edit'),
      icon: <BiEdit />,
    }),
    PartnerButtonColumn({
      id: 'button_3',
      onClick: showDeletePartner,
      title: t('columns.buttons.delete'),
      icon: <BiTrash />,
    }),
  ];

  const fetchData = async () => {
    const data = await UserService.getUsersWithStagedOwnedPlaces();
    setNewPartners(data);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  const acceptPartnerPlaces = async (user: PartnerUser) => {
    setLoading(true);

    await UserService.acceptPartnerPlaces(user._id);

    setShowConfirmAccept(false);
    await fetchData();

    setLoading(false);
  };

  const editPartnerPlaces = async (user: User) => {
    setLoading(true);

    await UserService.editPartnerStagedPlaces(
      user._id,
      user.stagedOwnedPlaces.map((item) => {
        if (typeof item === 'string') {
          return item;
        } else {
          return item._id;
        }
      }),
    );

    setShowEditPartnerPlacesModal(false);
    await fetchData();
    setLoading(false);
  };

  const deletePartnerStagedPlaces = async (user: PartnerUser) => {
    setLoading(true);

    await UserService.deletePartnerStagedPlaces(user._id);

    setShowConfirmDelete(false);
    await fetchData();
    setLoading(false);
  };

  return (
    <>
      {newPartners.length > 0 ? (
        <>
          <Header>{t('partnersTable.title')}</Header>
          <PartnersTable columns={columns} data={newPartners} />
        </>
      ) : (
        <NoApplication />
      )}
      {showConfirmAccept && (
        <ModalConfirmation
          onAccept={() => {
            if (selectedPartner) acceptPartnerPlaces(selectedPartner);
          }}
          onClose={() => setShowConfirmAccept(false)}
          title={t('partnersTable.confirmAccept')}
        />
      )}
      {showEditPartnerPlacesModal && (
        <ModalEditPartnerPlace
          onAccept={editPartnerPlaces}
          onClose={() => setShowEditPartnerPlacesModal(false)}
          user={selectedPartner}
        />
      )}
      {showConfirmDelete && (
        <ModalConfirmation
          onAccept={() => {
            if (selectedPartner) deletePartnerStagedPlaces(selectedPartner);
          }}
          onClose={() => setShowConfirmDelete(false)}
          title={t('partnersTable.confirmDelete')}
        />
      )}
    </>
  );
};

export default withLoading(Partners);
