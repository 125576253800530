import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';
import errorIcon from '../../assets/icons/error.svg';
import { Error, ErrorContainer, StyledLabel } from './Inputs.styles';
import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface StyledInputProps {
  isError?: boolean;
  fullWidth?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  background: ${colors.white};
  border: ${(props) =>
    props.isError ? `2px solid ${colors.error};` : '2px solid #d6d6d6;'};
  border-radius: 6px;
  padding: 0.6rem 0.7rem;
  align-items: center;
  width: ${(props) => (props.fullWidth ? 'calc(100% - 1.4rem)' : '90%')};

  @media (min-width: ${breakpoints.tablet}) {
    width: ${(props) => (props.fullWidth ? 'calc(100% - 1.4rem)' : '75%')};
  }

  @media (min-width: ${breakpoints.widescreen}) {
    width: ${(props) => (props.fullWidth ? 'calc(100% - 1.4rem)' : '50%')};
  }
`;

interface InputProps {
  label?: string;
  type: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const Input = (props: InputProps) => {
  const { t } = useTranslation();

  const {
    label,
    type,
    value,
    onChange,
    onKeyPress,
    required,
    error,
    disabled,
    fullWidth,
  } = props;

  return (
    <>
      {label && <StyledLabel required={required}>{label}</StyledLabel>}
      <StyledInput
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyPress}
        placeholder={label ?? t('search')}
        isError={!!error}
        disabled={disabled}
        fullWidth={fullWidth}
      />
      {error && (
        <ErrorContainer>
          <img src={errorIcon} alt="error" />
          <Error>{t(error)}</Error>
        </ErrorContainer>
      )}
    </>
  );
};
