import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Cell } from '../../../interfaces/Cell';

const Name = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
  white-space: nowrap;
`;

export const baseColumns = () => {
  const { t } = useTranslation();

  return [
    {
      Header: t('columns.base.name'),
      Cell: ({ row }: Cell) => (
        <>
          <Name>{row.original.name}</Name>
        </>
      ),
    },
    {
      Header: t('columns.base.category'),
      id: 'category',
      accessor: 'category',
    },
    {
      Header: t('columns.base.city'),
      id: 'city',
      accessor: 'city',
    },
    {
      Header: t('columns.base.street'),
      id: 'street',
      accessor: 'street',
    },
    {
      Header: t('columns.base.acceptedDogs'),
      Cell: ({ row }: Cell) => (
        <>
          {row.original.acceptedDogs
            .sort()
            .reverse()
            .map((item, idx) => (
              <span key={idx}>
                {idx !== 0 && ', '}
                {item}
              </span>
            ))}
        </>
      ),
    },
  ];
};
