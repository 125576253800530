import api from './api';

export class FilesService {
  static async getSignedUrlS3(contentType: string) {
    const res = await api.get(`/files?contentType=${contentType}`);

    return res.data;
  }

  static async uploadFileToS3(url: string, image: File) {
    const res = await api.put(url, image, {
      withCredentials: false,
    });

    return res.data;
  }

  static async deleteFileFromS3(image: string) {
    const res = await api.delete('/files', {
      data: {
        image,
      },
    });

    return res.data;
  }
}
