import { Tooltip } from '@mui/material';
import { PartnerCell } from '../../../interfaces/PartnerCell';
import { PartnerUser } from '../../../interfaces/User';
import { StyledIconButton } from './ButtonColumns.styles';

interface PartnerButtonColumnProps {
  id: string;
  onClick: (user: PartnerUser) => void;
  title: string;
  icon: JSX.Element;
}

export const PartnerButtonColumn = (props: PartnerButtonColumnProps) => {
  const { id, onClick, title, icon } = props;

  return {
    id,
    Cell: ({ row }: PartnerCell) => (
      <Tooltip title={title} placement="top">
        <StyledIconButton onClick={onClick.bind(null, row.original)}>
          {icon}
        </StyledIconButton>
      </Tooltip>
    ),
  };
};
