import { useReviews } from '../../store/reviewsContext';
import { Container } from '../containers/CardContainer.styles';
import styled from 'styled-components';
import { usePlace } from '../../store/placeContext';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { formatNum } from '../../utils/FormatNumber';

export const GridItem = styled(Grid)`
  z-index: 2;
  height: 18%;
`;

const ItemWrapper = styled.div`
  display: flex;
  * {
    margin: 0.2rem;
  }
`;

export const MobileCards = () => {
  const { t } = useTranslation();

  const { state: reviews } = useReviews();
  const { state: place } = usePlace();

  if (reviews) {
    return (
      <GridItem flexGrow={1} item xs={12}>
        <Container>
          <ItemWrapper>
            <h1>{t('cards.visitors')}: </h1>
            <p>{place.numberOfVisitors ?? 0}</p>
          </ItemWrapper>
          <ItemWrapper>
            <h1>{t('cards.published')}: </h1>
            <p>{place.isPublished ? t('yes') : t('no')}</p>
          </ItemWrapper>
          <ItemWrapper>
            <h1>{t('cards.googleRating')}: </h1>
            <p>
              {place.details?.rating
                ? `${formatNum(place.details?.rating, 2)}/5`
                : t('cards.noRatings')}
            </p>
          </ItemWrapper>
          <ItemWrapper>
            <h1>{t('cards.appRating')}: </h1>
            <p>
              {reviews.averageRating
                ? `${formatNum(reviews.averageRating, 2)}/5`
                : t('cards.noRatings')}
            </p>
          </ItemWrapper>
        </Container>
      </GridItem>
    );
  } else {
    return null;
  }
};
