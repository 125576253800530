import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';
import { KeyEnum } from '../../../../enums/KeyEnum';
import { SearchButton } from '../../../buttons/SearchButton';
import { Input } from '../../../inputs/Input';

interface SearchBarProps {
  value: string;
  handleSetValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch: () => void;
}

const Container = styled.div`
  display: flex;
  height: 3rem;
`;

export const SearchBar = (props: SearchBarProps) => {
  const { value, handleSetValue, handleSearch } = props;

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyEnum.Enter) handleSearch();
  };

  return (
    <Container>
      <Input
        onChange={handleSetValue}
        type="text"
        value={value}
        onKeyPress={onKeyPress}
      />
      <SearchButton onClick={handleSearch} />
    </Container>
  );
};
