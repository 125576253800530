import styled from 'styled-components';
import { colors } from '../../styles/variables';

interface ButtonProps {
  small?: boolean;
  btnColor?: string;
}

const Button = styled.button<ButtonProps>`
  width: ${(props) => (props.small ? 'auto' : '14rem')};
  height: ${(props) => (props.small ? 'auto' : '2.5rem')};
  padding: ${(props) => (props.small ? '0.3rem 0.8rem' : '0rem')};
  cursor: pointer;
  border: none;
  background: ${(props) =>
    props.btnColor
      ? props.btnColor
      : `
    linear-gradient(
    135deg,
    #ff6976 -12.5%,
    #ff7979 43.02%,
    #fdbeae 113.5%
  )
  `};
  border-radius: 30px;

  color: ${colors.white};
  font-size: ${(props) => (props.small ? '0.9rem' : '1rem')};
`;

interface MainButtonProps {
  title: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  small?: boolean;
  btnColor?: string;
}

export const MainButton = (props: MainButtonProps) => {
  const { title, onClick, type, disabled, small, btnColor } = props;

  return (
    <Button
      onClick={onClick}
      type={type ?? 'button'}
      disabled={disabled}
      small={small}
      btnColor={btnColor}
    >
      {title}
    </Button>
  );
};
