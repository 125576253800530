import styled from 'styled-components';
import { colors } from '../../styles/variables';

interface StyledLabelProps {
  required?: boolean;
}

export const StyledLabel = styled.label<StyledLabelProps>`
  font-weight: 500;
  line-height: 1.8rem;
  padding-top: 0.6rem;

  ${(props) =>
    props.required &&
    `::after {
      content: "*";
      color: ${colors.asteriksRed};
      padding-left: 0.3rem;
    }`}
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Error = styled.p`
  color: ${colors.error};
  margin: 0.3rem;
`;
