import { colors } from '../../../../../styles/variables';
import { MdTitle, MdOutlineDescription, MdOpenInBrowser } from 'react-icons/md';

import {
  FlexContainer,
  ImageContainer,
  OuterFlex,
  Text,
} from '../../PartnerHomepage.styles';

interface DescriptionSectionProps {
  title: string;
  description?: string;
  webPage?: string;
}

export const DescriptionSection = (props: DescriptionSectionProps) => {
  const { title, description, webPage } = props;

  return (
    <OuterFlex>
      <FlexContainer>
        <ImageContainer>
          <MdTitle color={colors.secondary} />
        </ImageContainer>
        <Text>{title}</Text>
      </FlexContainer>
      {webPage && (
        <FlexContainer>
          <ImageContainer>
            <MdOpenInBrowser color={colors.secondary} />
          </ImageContainer>
          <Text>{webPage}</Text>
        </FlexContainer>
      )}
      {description && (
        <FlexContainer>
          <ImageContainer>
            <MdOutlineDescription color={colors.secondary} />
          </ImageContainer>
          <Text>{description}</Text>
        </FlexContainer>
      )}
    </OuterFlex>
  );
};
