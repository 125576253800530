import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../buttons/MainButton';
import { Input } from '../inputs/Input';
import { ModalFailure } from '../modals/ModalFailure';
import { colors } from '../../styles/variables';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { ModalSuccess } from '../modals/ModalSuccess';
import { Paths } from '../../enums/Paths';
import { ValidateEmail } from './validators/ValidateEmail';
import { UserService } from '../../services/userService';

const LinkContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.secondary};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

export const AlreadyRegisteredForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const [status, setStatus] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });

  const submitHandler = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    setStatus((prevState) => {
      return {
        ...prevState,
        isSending: true,
      };
    });

    const isError = ValidateEmail({ email });

    if (isError) {
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });

      return;
    }

    try {
      await UserService.addUserAsPartner(email);

      setStatus((prevState) => {
        return {
          ...prevState,
          isSent: true,
          isSending: false,
        };
      });
    } catch (err) {
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });
    }
  };

  return (
    <>
      <FormContainer onSubmit={submitHandler}>
        <Input
          label={t('registerForm.email')}
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setStatus((prevState) => {
              return {
                ...prevState,
                isError: false,
              };
            });
          }}
          required
          error={status.isError ? 'registerForm.incorrectEmail' : undefined}
        />
        {status.isSent && (
          <ModalSuccess
            title={t('registerForm.alreadyRegisteredModalSuccess')}
            onClose={() => navigate(Paths.login)}
          />
        )}
        {status.isError && (
          <ModalFailure
            onClose={() =>
              setStatus((prevState) => {
                return {
                  ...prevState,
                  isError: false,
                };
              })
            }
          />
        )}
        <ButtonContainer>
          <MainButton
            title={t('buttons.send')}
            type="submit"
            disabled={status.isSending}
          />
        </ButtonContainer>
        {status.isSending && <LoadingSpinner />}
        <LinkContainer>
          {t('registerForm.hasAccount')}
          <StyledLink to={Paths.login}>{t('registerForm.login')}</StyledLink>
        </LinkContainer>
      </FormContainer>
    </>
  );
};
