import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Paths } from '../../../enums/Paths';
import { useActivePage } from '../../../hooks/useActivePage';
import { useFlags } from '../../../store/flagsContext';
import { usePlace } from '../../../store/placeContext';
import { colors } from '../../../styles/variables';
import { LoadingSpinner } from '../../spinner/LoadingSpinner';
import { LogoutButton } from './LogoutButton';
import { Navlink } from './Navlink';
import { SelectPlaceButton } from './SelectPlaceButton';

const SpinnerWrapper = styled.div`
  margin-top: 15rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 0.5rem;
  position: relative;
`;

const Separator = styled.div`
  height: 1px;
  width: 80%;
  background-color: ${colors.black};
  margin: 1rem 0;
  align-self: center;
`;

interface ButtonsPartnerProps {
  onNavClick: () => void;
}

const ButtonsPartner = (props: ButtonsPartnerProps) => {
  const { onNavClick } = props;

  const { t } = useTranslation();

  const activePage = useActivePage();

  const { state: flags } = useFlags();
  const { state: place } = usePlace();

  return (
    <ButtonsWrapper>
      <SelectPlaceButton />
      {flags.isLoading ? (
        <SpinnerWrapper>
          <LoadingSpinner noBackground />
        </SpinnerWrapper>
      ) : (
        <>
          {place.name ? (
            <>
              <Navlink
                to={Paths.partnerHome}
                active={activePage === Paths.partnerHome}
                title={t('navigationPartner.informations')}
                onClick={onNavClick}
              />
              <Navlink
                to={Paths.partnerImages}
                active={activePage === Paths.partnerImages}
                title={t('navigationPartner.images')}
                onClick={onNavClick}
              />
              <Navlink
                to={Paths.partnerComments}
                active={activePage === Paths.partnerComments}
                title={t('navigationPartner.comments')}
                onClick={onNavClick}
              />
            </>
          ) : (
            <Navlink
              to={Paths.partnerWelcome}
              active={activePage === Paths.partnerWelcome}
              title={t('navigationPartner.welcome')}
              onClick={onNavClick}
            />
          )}
          <Navlink
            to={Paths.partnerAddPlace}
            active={activePage === Paths.partnerAddPlace}
            title={t('navigationPartner.addPlace')}
            onClick={onNavClick}
          />
          <Navlink
            to={Paths.partnerAddPlaceFromList}
            active={activePage === Paths.partnerAddPlaceFromList}
            title={t('navigationPartner.addPlaceFromList')}
            onClick={onNavClick}
          />
          <Navlink
            to={Paths.partnerContact}
            active={activePage === Paths.partnerContact}
            title={t('navigationPartner.contact')}
            onClick={onNavClick}
          />
          <Separator />
          <Navlink
            to={Paths.partnerSettings}
            active={activePage === Paths.partnerSettings}
            title={t('navigationPartner.settings')}
            onClick={onNavClick}
          />
          <LogoutButton />
        </>
      )}
    </ButtonsWrapper>
  );
};

export default React.memo(ButtonsPartner);
