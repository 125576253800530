import * as React from 'react';
import { AcceptedDogsEnum } from '../enums/AcceptedDogsEnum';
import { Place } from '../interfaces/Places';

const defaultValue: Place = {
  name: '',
  category: '',
  phoneNumber: '',
  email: '',
  address: {
    city: '',
    country: '',
    state: '',
    street: '',
    zip: '',
  },
  description: '',
  webPage: '',
  numberOfVisitors: 0,
  numberOfRatings: 0,
  avatar: '',
  images: [],
  isPublished: false,
  _id: '',
  coordinates: {
    longitude: 0,
    latitude: 0,
  },
  acceptedDogs: [AcceptedDogsEnum.Small],
  petFeatures: [],
  googleId: '',
};

export enum PlaceContextTypes {
  setPlace = 'SET_PLACE',
}

interface Action {
  type: PlaceContextTypes;
  payload: Place;
}
type Dispatch = (action: Action) => void;

const PlaceContext = React.createContext<
  { state: Place; dispatch: Dispatch } | undefined
>(undefined);

function placeReducer(state: Place, action: Action) {
  switch (action.type) {
    case PlaceContextTypes.setPlace: {
      if (action.payload.stagedChanges) {
        return { ...action.payload, ...action.payload.stagedChanges };
      }
      return action.payload;
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

interface PlaceProviderProps {
  children: React.ReactNode;
}

const PlaceProvider = (props: PlaceProviderProps) => {
  const [state, dispatch] = React.useReducer(placeReducer, defaultValue);

  const value = { state, dispatch };

  return (
    <PlaceContext.Provider value={value}>
      {props.children}
    </PlaceContext.Provider>
  );
};

function usePlace() {
  const context = React.useContext(PlaceContext);
  if (context === undefined) {
    throw new Error('usePlace must be used within a PlaceProvider');
  }
  return context;
}

export { PlaceProvider, usePlace };
