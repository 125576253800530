import { useTranslation } from 'react-i18next';
import { RegisterForm } from '../../components/forms/RegisterForm';
import { Header } from '../../components/header/Header';

export const Register = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header>{t('registerForm.title')}</Header>
      <RegisterForm />
    </>
  );
};
