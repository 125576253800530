import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../components/buttons/MainButton';
import { Header } from '../../components/header/Header';
import { Input } from '../../components/inputs/Input';
import { ModalSuccess } from '../../components/modals/ModalSuccess';
import { Paths } from '../../enums/Paths';
import { AuthService } from '../../services/authService';
import { breakpoints, colors } from '../../styles/variables';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.secondary};
`;

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  @media (min-width: ${breakpoints.desktop}) {
    width: 70vw;
  }

  @media (min-width: ${breakpoints.widescreen}) {
    width: 50vw;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

export const ResetPasswordLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState('');

  const handleResetPasswordLink = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    try {
      await AuthService.sendResetPasswordLink(email);
      setIsSent(true);
    } catch (err: any) {
      setError(err.response.data.messageForUser);
    }
  };

  return (
    <>
      <Header>{t('resetPasswordLink.forgotPassword')}</Header>
      <div>
        <FormContainer onSubmit={handleResetPasswordLink}>
          <Input
            value={email}
            label={t('resetPasswordLink.email')}
            type="email"
            required
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            error={error}
          />
          <ButtonWrapper>
            <MainButton type={'submit'} title={t('buttons.send')} />
          </ButtonWrapper>
        </FormContainer>
        <StyledLink to={Paths.login}>{t('resetPasswordLink.back')}</StyledLink>
        {isSent && (
          <ModalSuccess
            onClose={() => {
              setIsSent(false);
              navigate(Paths.login);
            }}
            title={t('resetPasswordLink.modalSuccess')}
          />
        )}
      </div>
    </>
  );
};
