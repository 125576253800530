import { AcceptedDogsEnum } from '../../../../enums/AcceptedDogsEnum';
import { Place } from '../../../../interfaces/Places';

export interface FlattenedPlace {
  id: string;
  name: string;
  category: string;
  description: string;
  webPage: string;
  state: string;
  city: string;
  zip: string;
  street: string;
  avatar: string;
  images: string[];
  acceptedDogs: AcceptedDogsEnum[];
  googleId: string;
  email: string;
}

export const flattenPlaces = (places: Place[]) => {
  return places.map((place: Place) => ({
    id: place._id,
    name: place.name,
    category: place.category,
    description: place.description,
    webPage: place.webPage,
    state: place.address?.state,
    city: place.address?.city,
    zip: place.address?.zip,
    street: place.address?.street,
    images: place.images,
    avatar: place.avatar,
    acceptedDogs: place.acceptedDogs,
    phoneNumber: place.phoneNumber ?? '',
    googleId: place.googleId ?? '',
    email: place.email ?? '',
  }));
};
