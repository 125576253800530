import { useEffect, useState } from 'react';
import { itemsPerPage } from '../../../styles/variables';
import { TableOptions } from '../../../components/table/tableOptions/TableOptions';
import { BiCheck, BiTrash } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Review } from '../../../interfaces/Reviews';
import { reviewsColumns } from '../../../components/table/columns/TableReviewsColumns';
import { ReviewsService } from '../../../services/reviewsService';
import { ModalConfirmation } from '../../../components/modals/ModalConfirmation';
import { ReviewButtonColumn } from '../../../components/table/columns/ReviewButtonColumn';
import { ReviewsTable } from '../../../components/table/ReviewsTable';
import { NoApplication } from '../../../components/noApplication/NoApplication';
import { WithLoadingProps } from '../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../hocs/WithLoading';
import { showTableOptions } from '../../../utils/ShowTableOptions';

const Reviews = (props: WithLoadingProps) => {
  const { t } = useTranslation();

  const { setLoading } = props;

  const [reviews, setReviews] = useState<Review[]>([]);
  const [selectedReview, setSelectedReview] = useState<Review>();

  const [showConfirmAccept, setShowConfirmAccept] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchData = async () => {
    setLoading(true);

    const query = `?page=${
      currentPage + 1
    }&limit=${itemsPerPage}&search=${search}&sort=-createdAt`;

    const { data, count } = await ReviewsService.fetchReportedPlaceReviews(
      query,
    );

    setPageCount(Math.ceil(count / itemsPerPage));

    setReviews(data);

    setLoading(false);
  };

  const handleSearch = async () => {
    setCurrentPage(0);
    fetchData();
  };

  const showAcceptComment = (review: Review) => {
    setSelectedReview(review);
    setShowConfirmAccept(true);
  };

  const showDeleteComment = (review: Review) => {
    setSelectedReview(review);
    setShowConfirmDelete(true);
  };

  const columns = [
    ...reviewsColumns(),
    ReviewButtonColumn({
      id: 'button_1',
      onClick: showAcceptComment,
      title: t('columns.buttons.accept'),
      icon: <BiCheck />,
    }),
    ReviewButtonColumn({
      id: 'button_2',
      onClick: showDeleteComment,
      title: t('columns.buttons.delete'),
      icon: <BiTrash />,
    }),
  ];

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const acceptComment = async (review: Review) => {
    setLoading(true);

    await ReviewsService.restoreReview(review.placeId._id, review._id);

    setShowConfirmAccept(false);

    await fetchData();
  };

  const deleteComment = async (review: Review) => {
    setLoading(true);

    await ReviewsService.deleteReview(review.placeId._id, review._id);

    setShowConfirmDelete(false);

    await fetchData();
  };

  return (
    <>
      {showTableOptions(pageCount, search) && (
        <TableOptions
          paginationProps={{
            currentPage,
            pageCount,
            handlePageClick: async (e) => {
              setCurrentPage(e.selected);
            },
          }}
          searchBarProps={{
            value: search,
            handleSetValue: (e) => setSearch(e.target.value),
            handleSearch,
          }}
          header={t('reviews.title')}
        />
      )}
      {reviews && reviews.length > 0 ? (
        <ReviewsTable columns={columns} data={reviews} />
      ) : (
        <NoApplication />
      )}
      {showConfirmAccept && (
        <ModalConfirmation
          onAccept={() => {
            if (selectedReview) acceptComment(selectedReview);
          }}
          onClose={() => setShowConfirmAccept(false)}
          title={t('reviews.confirmAccept')}
        />
      )}
      {showConfirmDelete && (
        <ModalConfirmation
          onAccept={() => {
            if (selectedReview) deleteComment(selectedReview);
          }}
          onClose={() => setShowConfirmDelete(false)}
          title={t('reviews.confirmDelete')}
        />
      )}
    </>
  );
};

export default withLoading(Reviews);
