import styled from 'styled-components';
import { AuthService } from '../../../services/authService';
import { BiLogOut } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { clearLocalStorage } from '../../../utils/ClearLocalStorage';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding-left: 1rem;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
`;

const LogoutIcon = styled(BiLogOut)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const LogoutButton = () => {
  const { t } = useTranslation();

  const logoutHandler = async () => {
    await AuthService.logout();
    clearLocalStorage();
    window.location.reload();
  };

  return (
    <Container onClick={logoutHandler}>
      <LogoutIcon />
      <Text>{t('navigationPartner.logout')}</Text>
    </Container>
  );
};
