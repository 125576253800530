export const Paths = {
  login: '/login',
  register: '/register',
  alreadyRegistered: '/already-registered',
  activateAccount: (userId = ':userId', token = ':token') =>
    `/user/${userId}/activate/${token}`,
  resetPassword: (id = ':id') => `/reset-password/${id}`,
  resetPasswordLink: '/reset-password-link',
  admin: '/admin',
  adminUnpublished: '/admin/unpublished',
  adminStagedChanges: '/admin/staged-changes',
  adminStagedChangesItem: (id = ':id') => `/admin/staged-changes/${id}`,
  adminEditPlace: (id = ':id') => `/admin/edit-place/${id}`,
  adminReviews: '/admin/reviews',
  adminPartners: '/admin/partners',
  adminAddPlace: '/admin/add-place',
  partnerHome: '/',
  partnerWelcome: '/welcome',
  partnerAddPlace: '/add-place',
  partnerAddPlaceFromList: '/add-place-list',
  partnerImages: '/images',
  partnerComments: '/comments',
  partnerContact: '/contact',
  partnerSettings: '/settings',
  partnerChangePassword: '/settings/change-password',
};
