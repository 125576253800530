import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useRole } from '../../hooks/useRole';
import { UserRolesEnum } from '../../enums/UserRolesEnum';
import { Paths } from '../../enums/Paths';

export const PublicRoute: React.FC = () => {
  const auth = useAuth();
  const role = useRole();

  if (!auth) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={role === UserRolesEnum.Admin ? Paths.admin : Paths.partnerHome}
      />
    );
  }
};
