import { getConfig } from '../config/GetConfig';
import { ImageSender } from '../interfaces/ImageSender';
import { ImageType } from '../interfaces/ImageType';
import { FilesService } from '../services/filesService';
import { PlacesService } from '../services/placesService';

const sendAvatar = async (
  sender: ImageSender,
  placeId: string,
  imageUrl: string,
) => {
  if (sender === ImageSender.admin) {
    await PlacesService.addAvatar(placeId, imageUrl);
  }
  if (sender === ImageSender.partner) {
    await PlacesService.partnerAddAvatar(placeId, imageUrl);
  }
};

const sendImage = async (
  sender: ImageSender,
  placeId: string,
  imageUrl: string,
) => {
  if (sender === ImageSender.admin) {
    await PlacesService.addImage(placeId, imageUrl);
  }
  if (sender === ImageSender.partner) {
    await PlacesService.partnerAddImage(placeId, imageUrl);
  }
};

interface UploadSingleImageProps {
  image: File;
  placeId: string;
  type: ImageType;
  sender: ImageSender;
}

const uploadSingleImage = async (props: UploadSingleImageProps) => {
  const { image, placeId, type, sender } = props;

  const s3Url = getConfig().s3.url;
  if (!placeId || !s3Url) return;

  const urlResponse: {
    url: string;
    key: string;
  } = await FilesService.getSignedUrlS3(image.type.split('/')[1]);

  await FilesService.uploadFileToS3(urlResponse.url, image);

  const imageUrl = `${s3Url}/${urlResponse.key}`;
  if (type === ImageType.avatar) {
    sendAvatar(sender, placeId, imageUrl);
  } else {
    sendImage(sender, placeId, imageUrl);
  }
};

interface UploadImagesProps {
  avatar?: FileList;
  images?: FileList;
  placeId?: string;
  sender: ImageSender;
}

export const uploadImages = async (props: UploadImagesProps) => {
  const { images, avatar, placeId, sender } = props;

  if (!placeId) return;

  if (avatar) {
    await uploadSingleImage({
      image: avatar[0],
      placeId,
      type: ImageType.avatar,
      sender,
    });
  }

  if (images && images.length > 0) {
    await Promise.all(
      Array.from(images).map(async (img) => {
        return uploadSingleImage({
          image: img,
          placeId,
          type: ImageType.image,
          sender,
        });
      }),
    );
  }
};
