import { AdditionalInfoSection } from './components/content/AdditionalInfoSection';
import { AddressSection } from './components/content/AddressSection';
import { DescriptionSection } from './components/content/DescriptionSection';
import { OpeningHoursSection } from './components/content/OpeningHoursSection';
import { TitleSection } from './components/content/TitleSection';
import { HorizontalDivider } from '../../../components/dividers/HorizontalDivider';
import { VerticalDivider } from '../../../components/dividers/VerticalDivider';
import { Place } from '../../../interfaces/Places';
import { ColumnFlexContainer } from './PartnerHomepage.styles';
import { useIsTablet } from '../../../hooks/useIsTablet';

interface PartnerHomepageContentProps {
  place: Place;
}

export const PartnerHomepageContent = (props: PartnerHomepageContentProps) => {
  const { place } = props;
  const isTablet = useIsTablet();

  return (
    <>
      <ColumnFlexContainer>
        <TitleSection title={place.name} />
        <HorizontalDivider />
        <AddressSection
          address={place.address}
          phoneNumber={place.phoneNumber}
          googleId={place.googleId}
        />
        <HorizontalDivider />
        <DescriptionSection
          title={place.name}
          description={place.description}
          webPage={place.webPage}
        />
      </ColumnFlexContainer>
      {!isTablet && <VerticalDivider />}
      <ColumnFlexContainer>
        <AdditionalInfoSection
          type={place.category}
          acceptedDogs={place.acceptedDogs}
          features={place.petFeatures}
        />
        {place.details && (
          <>
            <HorizontalDivider />
            <OpeningHoursSection details={place.details} />
          </>
        )}
      </ColumnFlexContainer>
    </>
  );
};
