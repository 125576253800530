import styled from 'styled-components';
import { colors } from '../../styles/variables';
import logo from '../../assets/icons/walkieDoggieLogo.svg';
import { Paths } from '../../enums/Paths';

const Header = styled.header`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${colors.primary};
  padding-left: 6px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Link = styled.a`
  text-decoration: none;
`;

interface LogoProps {
  size: number;
}

export const Logo = (props: LogoProps) => {
  const { size } = props;

  return (
    <Link href={Paths.partnerHome}>
      <Container>
        <img src={logo} width={size} height={size} alt="logo" loading="eager" />
        <Header>WalkieDoggie</Header>
      </Container>
    </Link>
  );
};
