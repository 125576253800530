import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import modalClose from '../../assets/icons/modalClose.svg';
import { PartnerUser, User } from '../../interfaces/User';
import { colors } from '../../styles/variables';
import { MainButton } from '../buttons/MainButton';
import { AssignToPlaceButton } from '../navigationPartner/buttons/AssignToPlaceButton';
import { CloseModal, Container, Wrapper } from './Modals.styles';

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 3rem;
`;

interface ModalEditPartnerPlaceProps {
  onClose: () => void;
  onAccept: (user: User) => void;
  user?: PartnerUser;
}

export const ModalEditPartnerPlace = (props: ModalEditPartnerPlaceProps) => {
  const { t } = useTranslation();

  const { onClose, onAccept, user } = props;

  const ids = user?.stagedOwnedPlaces.map((item) => item._id) ?? [];
  const [selectedPlaces, setSelectedPlaces] = useState<string[]>(ids);

  return (
    <Wrapper>
      <Container id="modal">
        <CloseModal onClick={onClose}>
          <img src={modalClose} alt="modal-close" />
        </CloseModal>
        <p>{t('partnersTable.modalEditPartnerPlace')}</p>
        <AssignToPlaceButton
          selectedPlaces={selectedPlaces}
          setSelectedPlaces={setSelectedPlaces}
        />
        <ButtonsWrapper>
          <MainButton
            title={t('yes')}
            onClick={() => {
              if (user) {
                onAccept({ ...user, stagedOwnedPlaces: selectedPlaces });
              }
            }}
            btnColor={colors.primary}
          />
          <MainButton
            title={t('no')}
            onClick={onClose}
            btnColor={colors.asteriksRed}
          />
        </ButtonsWrapper>
      </Container>
    </Wrapper>
  );
};
