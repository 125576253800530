import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { WithLoadingProps } from '../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../hocs/WithLoading';
import { AuthService } from '../../services/authService';
import { ActivationError } from './components/ActivationError';
import { ActivationSuccess } from './components/ActivationSuccess';

const ActivateAccount = (props: WithLoadingProps) => {
  const { setLoading } = props;

  const { userId, token } = useParams();

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const activateAccount = async () => {
      setLoading(true);

      try {
        if (userId && token) {
          await AuthService.activateUserWithToken(userId, token);
        }
        setLoading(false);
      } catch {
        setIsError(true);
        setLoading(false);
      }
    };

    activateAccount();
  }, []);

  return <>{isError ? <ActivationError /> : <ActivationSuccess />}</>;
};

export default withLoading(ActivateAccount);
