import { FormTypes } from '../../../interfaces/FormTypes';
import { PartnerPlaceForm } from './components/edit/PartnerPlaceForm';

interface PartnerHomepageEditProps {
  onCancelEdit: () => void;
}

export const PartnerHomepageEdit = (props: PartnerHomepageEditProps) => {
  const { onCancelEdit } = props;
  return (
    <>
      <PartnerPlaceForm type={FormTypes.edit} onCancelEdit={onCancelEdit} />
    </>
  );
};
