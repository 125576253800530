import { useEffect, useState } from 'react';
import { breakpointsNum } from '../styles/variables';

export const useIsWidescreen = () => {
  const [isWidescreen, setIsWidescreen] = useState<boolean | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsWidescreen(window.outerWidth <= breakpointsNum.widescreen);
    };

    setIsWidescreen(window.outerWidth <= breakpointsNum.widescreen);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isWidescreen;
};
