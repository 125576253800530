import styled from 'styled-components';
import { colors } from '../../../../../styles/variables';
import { GoLocation } from 'react-icons/go';
import { AiOutlinePhone } from 'react-icons/ai';
import { Address } from '../../../../../interfaces/Places';
import { FcGoogle } from 'react-icons/fc';
import { BsQuestionCircleFill } from 'react-icons/bs';
import {
  FlexContainer,
  ImageContainer,
  OuterFlex,
  Text,
} from '../../PartnerHomepage.styles';
import { useEffect, useState } from 'react';
import { ObjectType } from '../../../../../interfaces/ObjectType';
import { PlacesService } from '../../../../../services/placesService';
import { getConfig } from '../../../../../config/GetConfig';

const InfoContainer = styled.a`
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  color: ${colors.black};
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

interface AddressSectionProps {
  address: Address;
  phoneNumber: string;
  googleId: string;
}

export const AddressSection = (props: AddressSectionProps) => {
  const { address, phoneNumber, googleId } = props;

  const [statesTranslations, setStatesTranslations] = useState<ObjectType>();

  useEffect(() => {
    const fetchTranslations = async () => {
      const statesTranslationsRes = await PlacesService.getStatesTranslations();

      setStatesTranslations(statesTranslationsRes);
    };

    fetchTranslations();
  }, []);

  return (
    <OuterFlex>
      {address && (
        <FlexContainer>
          <ImageContainer>
            <GoLocation color={colors.secondary} />
          </ImageContainer>
          <Text>
            {address.street} {address.zip} {address.city},{' '}
            {statesTranslations
              ? statesTranslations[address.state]
              : address.state}
          </Text>
        </FlexContainer>
      )}
      {phoneNumber && (
        <FlexContainer>
          <ImageContainer>
            <AiOutlinePhone color={colors.secondary} />
          </ImageContainer>
          <Text>{phoneNumber}</Text>
        </FlexContainer>
      )}
      {googleId && (
        <FlexContainer>
          <ImageContainer>
            <FcGoogle />
          </ImageContainer>
          <Text>{googleId}</Text>
          <InfoContainer href={getConfig().googleId.url} target="_blank">
            <BsQuestionCircleFill />
          </InfoContainer>
        </FlexContainer>
      )}
    </OuterFlex>
  );
};
