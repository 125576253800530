import styled from 'styled-components';
import {
  IStagedChanges,
  IStagedChangesKeys,
} from '../helpers/flattenStagedChanges';
import { ComparedItem } from './ComparedItem';
import { ComparedImageItem } from './ComparedImageItem';
import { TableHead } from './TableHead';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../../interfaces/Places';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2.5rem);
  padding: 1rem;
  border: 1px solid black;
  border-radius: 1rem;
  margin-bottom: 1rem;
`;

interface ChangesDiffProps {
  place: IStagedChanges;
  newPlace: IStagedChanges;
}

export const ChangesDiff = (props: ChangesDiffProps) => {
  const { t } = useTranslation();
  const { place, newPlace } = props;

  const isPropertyPresent = (prop: keyof IStagedChanges) => {
    return !!place[prop] && !!newPlace[prop];
  };

  const isPropertyPresentInAddress = (prop: keyof Address) => {
    return (
      !!place.address &&
      !!newPlace.address &&
      !!place.address[prop] &&
      !!newPlace.address[prop]
    );
  };

  const showPlace = () => {
    let flag = false;

    // TODO: replace any with better type
    IStagedChangesKeys.forEach((placeKey: any) => {
      if (isPropertyPresent(placeKey)) {
        flag = true;
      }
    });

    return flag;
  };

  const showAvatar =
    place.avatar && newPlace.avatar && place.avatar !== newPlace.avatar;
  const showImages =
    place.images && newPlace.images && place.images !== newPlace.images;

  return (
    <>
      {showPlace() && (
        <FlexColumn>
          <TableHead
            actualTitle={t('stagedChanges.current')}
            newTitle={t('stagedChanges.new')}
          />
          {isPropertyPresent('category') && (
            <ComparedItem
              title={t('place.category')}
              oldItem={place.category!}
              newItem={newPlace.category!}
            />
          )}
          {isPropertyPresent('name') && (
            <ComparedItem
              title={t('place.name')}
              oldItem={place.name!}
              newItem={newPlace.name!}
            />
          )}
          {isPropertyPresentInAddress('state') && (
            <ComparedItem
              title={t('place.state')}
              oldItem={place.address!.state}
              newItem={newPlace.address!.state}
            />
          )}
          {isPropertyPresentInAddress('city') && (
            <ComparedItem
              title={t('place.city')}
              oldItem={place.address!.city}
              newItem={newPlace.address!.city}
            />
          )}
          {isPropertyPresentInAddress('zip') && (
            <ComparedItem
              title={t('place.zip')}
              oldItem={place.address!.zip}
              newItem={newPlace.address!.zip}
            />
          )}
          {isPropertyPresentInAddress('street') && (
            <ComparedItem
              title={t('place.street')}
              oldItem={place.address!.street}
              newItem={newPlace.address!.street}
            />
          )}
          {isPropertyPresent('phoneNumber') && (
            <ComparedItem
              title={t('place.phoneNumber')}
              oldItem={place.phoneNumber!}
              newItem={newPlace.phoneNumber!}
            />
          )}
          {isPropertyPresent('email') && (
            <ComparedItem
              title={t('place.email')}
              oldItem={place.email!}
              newItem={newPlace.email!}
            />
          )}
          {isPropertyPresent('googleId') && (
            <ComparedItem
              title={t('place.googleId')}
              oldItem={place.googleId!}
              newItem={newPlace.googleId!}
            />
          )}
          {isPropertyPresent('description') && (
            <ComparedItem
              title={t('place.description')}
              oldItem={place.description!}
              newItem={newPlace.description!}
            />
          )}
          {isPropertyPresent('webPage') && (
            <ComparedItem
              title={t('place.webPage')}
              oldItem={place.webPage!}
              newItem={newPlace.webPage!}
            />
          )}
          {isPropertyPresent('petFeatures') && (
            <ComparedItem
              title={t('place.features')}
              oldItem={place.petFeatures!.toString()}
              newItem={newPlace.petFeatures!.toString()}
            />
          )}
          {isPropertyPresent('acceptedDogs') && (
            <ComparedItem
              title={t('place.acceptedDogs')}
              oldItem={place.acceptedDogs!.toString()}
              newItem={newPlace.acceptedDogs!.toString()}
            />
          )}
        </FlexColumn>
      )}
      {(!!showAvatar || !!showImages) && (
        <FlexColumn>
          <TableHead
            actualTitle={t('images.deletedImages')}
            newTitle={t('images.newImages')}
          />
          {showAvatar && (
            <ComparedImageItem
              title={t('place.avatar')}
              oldImages={[place.avatar!]}
              newImages={[newPlace.avatar!]}
            />
          )}
          {showImages && (
            <ComparedImageItem
              title={t('place.images')}
              oldImages={place.images!}
              newImages={newPlace.images!}
            />
          )}
        </FlexColumn>
      )}
    </>
  );
};
