import { Box } from '@mui/material';

import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';

export const Container = styled(Box)`
  padding: 0.5rem;
  height: 100%;
  background-color: ${colors.white};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: auto;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 1rem;
    justify-content: center;
    overflow: hidden;
  }
`;

export const Header = styled.h3`
  color: ${colors.grey};
  font-weight: 500;
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardText = styled.h2`
  font-size: 2rem;
  color: ${colors.black};
  font-weight: 700;
  margin: 0.5rem;
`;
