import styled from 'styled-components';
import { breakpoints, colors } from '../../styles/variables';

export const Wrapper = styled.div`
  padding: 0 1rem;
  background: ${colors.white};
  min-height: calc(100vh - 7.5rem);
  position: relative;
  padding: 2rem 1rem;

  * {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 2rem 2rem;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 2rem 3rem;
  }

  @media screen and (min-width: ${breakpoints.widescreen}) {
    padding: 2rem 5rem;
  }

  @media screen and (min-width: ${breakpoints.uhd}) {
    padding: 2rem 10rem;
  }
`;
