import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { breakpoints, colors } from '../../../../../styles/variables';

const Container = styled.div`
  width: 70%;
  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

const Header = styled.h1`
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0 0 0.5rem;
`;

const Description = styled.h2`
  font-weight: 500;
  color: ${colors.grey};
  margin: 0;
`;

interface TitleSectionProps {
  title: string;
}

export const TitleSection = (props: TitleSectionProps) => {
  const { t } = useTranslation();

  const { title } = props;

  return (
    <Container>
      <Header>{title}</Header>
      <Description>{t('partner.description')}</Description>
    </Container>
  );
};
