import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div``;

const Header = styled.h1`
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0 0 0.5rem;
`;

export const TitleSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('partnerChangePassword.title')}</Header>
    </Container>
  );
};
