import ReactPaginate from 'react-paginate';

interface PaginationProps {
  currentPage: number;
  pageCount: number;
  handlePageClick: (e: { selected: number }) => void;
}

export const Pagination = (props: PaginationProps) => {
  const { currentPage, pageCount, handlePageClick } = props;

  return (
    <ReactPaginate
      breakLabel=".."
      nextLabel=" > "
      previousLabel=" < "
      onPageChange={handlePageClick}
      pageCount={pageCount}
      renderOnZeroPageCount={undefined}
      containerClassName={'pagination'}
      activeClassName={'active'}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      forcePage={currentPage}
    />
  );
};
