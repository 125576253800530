import { useTranslation } from 'react-i18next';
import { AlreadyRegisteredForm } from '../../components/forms/AlreadyRegisteredForm';
import { Header } from '../../components/header/Header';

export const AlreadyRegistered = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header>{t('registerForm.alreadyRegistered')}</Header>
      <AlreadyRegisteredForm />
    </>
  );
};
