import { Tooltip } from '@mui/material';
import { ReviewCell } from '../../../interfaces/ReviewCell';
import { Review } from '../../../interfaces/Reviews';
import { StyledIconButton } from './ButtonColumns.styles';

interface ReviewButtonColumnProps {
  id: string;
  onClick: (review: Review) => void;
  title: string;
  icon: JSX.Element;
}

export const ReviewButtonColumn = (props: ReviewButtonColumnProps) => {
  const { id, onClick, title, icon } = props;

  return {
    id,
    Cell: ({ row }: ReviewCell) => (
      <Tooltip title={title} placement="top">
        <StyledIconButton onClick={onClick.bind(null, row.original)}>
          {icon}
        </StyledIconButton>
      </Tooltip>
    ),
  };
};
