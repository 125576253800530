import * as React from 'react';
import { Reviews } from '../interfaces/Reviews';

const defaultValue: Reviews = {
  averageRating: 0,
  reviews: [],
};

export enum PlaceContextReviewsTypes {
  setPlaceReviews = 'SET_PLACE_REVIEWS',
}

interface Action {
  type: PlaceContextReviewsTypes;
  payload: Reviews;
}
type Dispatch = (action: Action) => void;

const ReviewsContext = React.createContext<
  { state: Reviews; dispatch: Dispatch } | undefined
>(undefined);

function reviewsReducer(state: Reviews, action: Action) {
  switch (action.type) {
    case PlaceContextReviewsTypes.setPlaceReviews: {
      return action.payload;
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
}

interface ReviewsProviderProps {
  children: React.ReactNode;
}

const ReviewsProvider = (props: ReviewsProviderProps) => {
  const [state, dispatch] = React.useReducer(reviewsReducer, defaultValue);

  const value = { state, dispatch };

  return (
    <ReviewsContext.Provider value={value}>
      {props.children}
    </ReviewsContext.Provider>
  );
};

function useReviews() {
  const context = React.useContext(ReviewsContext);
  if (context === undefined) {
    throw new Error('usePlace must be used within a ReviewsProvider');
  }
  return context;
}

export { ReviewsProvider, useReviews };
