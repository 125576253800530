import api from './api';

export class GoogleService {
  static async getGoogleImages(placeId: string): Promise<string[]> {
    const res = await api.get(`/places/${placeId}/google-images`);

    return res.data;
  }

  static async findAutocompletePlaces(value: string) {
    const res = await api.get(`/places/autocomplete/${value}`);

    return res.data;
  }
}
