import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Place } from '../../../interfaces/Places';
import { Reviews } from '../../../interfaces/Reviews';
import { PlacesService } from '../../../services/placesService';
import { UserService } from '../../../services/userService';
import { FlagsContextTypes, useFlags } from '../../../store/flagsContext';
import { PlaceContextTypes, usePlace } from '../../../store/placeContext';
import {
  PlaceContextReviewsTypes,
  useReviews,
} from '../../../store/reviewsContext';

export const SelectPlaceButton = () => {
  const { t } = useTranslation();

  const [usersOwnedPlaces, setUsersOwnedPlaces] = useState<Place[]>([]);

  const { state: flags, dispatch: flagsDispatch } = useFlags();
  const { state: placeState, dispatch: placeDispatch } = usePlace();
  const { dispatch: reviewsDispatch } = useReviews();

  const setLoading = (loading: boolean) => {
    flagsDispatch({ type: FlagsContextTypes.setLoading, payload: loading });
  };
  const setPlaceAdded = (placeAdded: boolean) => {
    flagsDispatch({
      type: FlagsContextTypes.setPlaceAdded,
      payload: placeAdded,
    });
  };
  const setPlace = (place: Place) => {
    placeDispatch({ type: PlaceContextTypes.setPlace, payload: place });
  };
  const setReviews = (reviews: Reviews) => {
    reviewsDispatch({
      type: PlaceContextReviewsTypes.setPlaceReviews,
      payload: reviews,
    });
  };

  useEffect(() => {
    if (!flags.isLoading && !flags.isPlaceAdded) return;

    const fetchPlacesAndReviews = async () => {
      const places = await UserService.getCurrentUserOwnedPlaces();

      if (places && places.length > 0) {
        const currentPlaceId = localStorage.getItem('placeId') ?? places[0]._id;
        const currentPlace =
          places.find((item) => item._id === localStorage.getItem('placeId')) ??
          places[0];

        const reviews = await PlacesService.fetchPlaceReviews(currentPlaceId);
        try {
          const details = await PlacesService.fetchPlaceDetails(currentPlaceId);
          setPlace({ ...currentPlace, details });
        } catch {
          setPlace(currentPlace);
        }

        setUsersOwnedPlaces(places);
        setReviews(reviews);
      }
      setLoading(false);
      setPlaceAdded(false);
    };

    fetchPlacesAndReviews();
  }, [flags.isPlaceAdded]);

  const handleChange = async (event: SelectChangeEvent) => {
    const newPlace = usersOwnedPlaces.find(
      (item) => item._id === event.target.value,
    );

    if (newPlace) {
      const reviews = await PlacesService.fetchPlaceReviews(newPlace._id);
      setReviews(reviews);

      try {
        const details = await PlacesService.fetchPlaceDetails(newPlace._id);
        setPlace({ ...newPlace, details });
      } catch {
        setPlace(newPlace);
      }
      localStorage.setItem('placeId', newPlace._id);
    }
  };

  const returnUsersPlaces = () =>
    usersOwnedPlaces.map((item) => (
      <MenuItem value={item._id} key={item._id}>
        {placeState._id === item._id ? placeState.name : item.name}
      </MenuItem>
    ));

  if (!flags.isLoading && usersOwnedPlaces.length) {
    return (
      <Box marginBottom={2}>
        <InputLabel>{t('buttons.selectPlace')}</InputLabel>
        <Select
          value={placeState?._id}
          onChange={handleChange}
          style={{ width: '25ch' }}
        >
          {returnUsersPlaces()}
        </Select>
      </Box>
    );
  } else return null;
};
