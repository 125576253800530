import { PlacesService } from '../services/placesService';
import { PlaceContextTypes, usePlace } from '../store/placeContext';

export const useRefetchPlace = () => {
  const { dispatch } = usePlace();

  const refetchPlace = async (placeId: string) => {
    const place = await PlacesService.fetchPlace(placeId);
    try {
      const details = await PlacesService.fetchPlaceDetails(place._id);
      dispatch({
        type: PlaceContextTypes.setPlace,
        payload: { ...place, details },
      });
    } catch {
      dispatch({ type: PlaceContextTypes.setPlace, payload: place });
    }
  };

  return refetchPlace;
};
