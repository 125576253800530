import { MdOutlineCategory, MdStar } from 'react-icons/md';
import { FaDog } from 'react-icons/fa';
import { colors } from '../../../../../styles/variables';
import {
  FlexContainer,
  ImageContainer,
  Label,
  LabeledTextContainer,
  OuterFlex,
  Text,
} from '../../PartnerHomepage.styles';
import { AcceptedDogsEnum } from '../../../../../enums/AcceptedDogsEnum';
import { PlacesService } from '../../../../../services/placesService';
import { useEffect, useState } from 'react';
import { ObjectType } from '../../../../../interfaces/ObjectType';
import { Chip, Stack } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledStack = styled(Stack)`
  padding-top: 0.5rem;
`;

interface AdditionalInfoSectionProps {
  type: string;
  acceptedDogs: AcceptedDogsEnum[];
  features: string[];
}

export const AdditionalInfoSection = (props: AdditionalInfoSectionProps) => {
  const { t } = useTranslation();
  const { type, acceptedDogs, features } = props;

  const [categoriesTranslations, setCategoriesTranslations] =
    useState<ObjectType>();
  const [acceptedDogsTranslations, setAcceptedDogsTranslations] =
    useState<ObjectType>();
  const [featuresTranslations, setFeaturesTranslations] =
    useState<ObjectType>();

  useEffect(() => {
    const fetchTypes = async () => {
      const categoriesTranslationsRes =
        await PlacesService.getCategoriesTranslations();
      const acceptedDogsTranslationsRes =
        await PlacesService.getAcceptedDogsTranslations();
      const featuresTranslationsRes =
        await PlacesService.getFeaturesTranslations();

      setCategoriesTranslations(categoriesTranslationsRes);
      setAcceptedDogsTranslations(acceptedDogsTranslationsRes);
      setFeaturesTranslations(featuresTranslationsRes);
    };

    fetchTypes();
  }, []);

  const getAcceptedDogs = () => {
    return acceptedDogs.map((item, idx) => (
      <span key={idx}>
        {idx !== 0 && ', '}
        {acceptedDogsTranslations ? acceptedDogsTranslations[item] : type}
      </span>
    ));
  };

  const getFeatures = () => {
    if (featuresTranslations) {
      return features.map((item, idx: number) => (
        <Chip
          key={idx}
          label={featuresTranslations[item] ?? item}
          size="small"
        />
      ));
    }
  };

  return (
    <OuterFlex>
      <FlexContainer>
        <ImageContainer>
          <MdOutlineCategory color={colors.secondary} />
        </ImageContainer>
        <LabeledTextContainer>
          <Label>{t('place.category')}</Label>
          <Text>
            {categoriesTranslations ? categoriesTranslations[type] : type}
          </Text>
        </LabeledTextContainer>
      </FlexContainer>
      <FlexContainer>
        <ImageContainer>
          <FaDog color={colors.secondary} />
        </ImageContainer>
        <LabeledTextContainer>
          <Label>{t('place.acceptedDogs')}</Label>
          <Text>{getAcceptedDogs()}</Text>
        </LabeledTextContainer>
      </FlexContainer>
      <FlexContainer>
        <ImageContainer>
          <MdStar color={colors.secondary} />
        </ImageContainer>
        <LabeledTextContainer>
          <Label>{t('partner.features')}</Label>
          <Text>{t('partner.featuresDescription')}</Text>
          <StyledStack direction="row" spacing={1}>
            {getFeatures()}
          </StyledStack>
        </LabeledTextContainer>
      </FlexContainer>
    </OuterFlex>
  );
};
