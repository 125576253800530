import styled from 'styled-components';

const FlexItem = styled.div`
  display: flex;
`;

const Text = styled.h2`
  font-size: 1.2rem;
  font-weight: 400;
  width: 50%;
  text-align: center;
`;

const Header = styled.h2`
  width: 30%;
`;

const Items = styled.div`
  width: 100%;
  display: flex;
`;

interface TableHeadProps {
  actualTitle: string;
  newTitle: string;
}

export const TableHead = (props: TableHeadProps) => {
  const { actualTitle, newTitle } = props;

  return (
    <FlexItem>
      <Header></Header>
      <Items>
        <Text>{actualTitle}</Text>
        <Text>{newTitle}</Text>
      </Items>
    </FlexItem>
  );
};
