import { Navigate, Route, Routes } from 'react-router-dom';

import { Paths } from '../enums/Paths';
import ActivateAccount from '../pages/activateAccount/ActivateAccount';
import { AddPlace } from '../pages/admin/addPlace/AddPlace';
import { EditPlace } from '../pages/admin/editPlace/EditPlace';
import AdminHomepage from '../pages/admin/homepage/AdminHomepage';
import Partners from '../pages/admin/partners/Partners';
import Reviews from '../pages/admin/reviews/Reviews';
import ReviewStagedChanges from '../pages/admin/stagedChanges/ReviewStagedChanges';
import StagedChanges from '../pages/admin/stagedChanges/StagedChanges';
import Unpublished from '../pages/admin/unpublished/Unpublished';
import { WelcomePage } from '../pages/admin/welcome/WelcomePage';
import { AlreadyRegistered } from '../pages/alreadyRegistered/AlreadyRegistered';
import { Login } from '../pages/login/Login';
import { PartnerAddPlace } from '../pages/partner/addPlace/PartnerAddPlace';
import { AddPlaceFromList } from '../pages/partner/addPlaceFromList/AddPlaceFromList';
import { PartnerChangePassword } from '../pages/partner/changePassword/PartnerChangePassword';
import { PartnerComments } from '../pages/partner/comments/PartnerComments';
import { PartnerContact } from '../pages/partner/contact/PartnerContact';
import { PartnerHomepage } from '../pages/partner/homepage/PartnerHomepage';
import PartnerImages from '../pages/partner/images/PartnerImages';
import { PartnerSettings } from '../pages/partner/settings/PartnerSettings';
import { Register } from '../pages/register/Register';
import { ResetPassword } from '../pages/resetPassword/ResetPassword';
import { ResetPasswordLink } from '../pages/resetPasswordLink/ResetPasswordLink';
import { AdminRoute } from './routeGuards/AdminRoute';
import { PartnerRoute } from './routeGuards/PartnerRoute';
import { PublicRoute } from './routeGuards/PublicRoute';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.login} element={<PublicRoute />}>
        <Route path={Paths.login} element={<Login />} />
      </Route>
      <Route path={Paths.register} element={<PublicRoute />}>
        <Route path={Paths.register} element={<Register />} />
      </Route>
      <Route path={Paths.alreadyRegistered} element={<PublicRoute />}>
        <Route path={Paths.alreadyRegistered} element={<AlreadyRegistered />} />
      </Route>
      <Route path={Paths.activateAccount()} element={<PublicRoute />}>
        <Route path={Paths.activateAccount()} element={<ActivateAccount />} />
      </Route>
      <Route path={Paths.resetPassword()} element={<PublicRoute />}>
        <Route path={Paths.resetPassword()} element={<ResetPassword />} />
      </Route>
      <Route path={Paths.resetPasswordLink} element={<PublicRoute />}>
        <Route path={Paths.resetPasswordLink} element={<ResetPasswordLink />} />
      </Route>
      <Route path="*" element={<PublicRoute />}>
        <Route path="*" element={<Navigate to={Paths.login} replace />} />
      </Route>
      <Route path={Paths.admin} element={<AdminRoute />}>
        <Route path={Paths.admin} element={<AdminHomepage />} />
      </Route>
      <Route path={Paths.adminUnpublished} element={<AdminRoute />}>
        <Route path={Paths.adminUnpublished} element={<Unpublished />} />
      </Route>
      <Route path={Paths.adminStagedChanges} element={<AdminRoute />}>
        <Route path={Paths.adminStagedChanges} element={<StagedChanges />} />
      </Route>
      <Route path={Paths.adminStagedChangesItem()} element={<AdminRoute />}>
        <Route
          path={Paths.adminStagedChangesItem()}
          element={<ReviewStagedChanges />}
        />
      </Route>
      <Route path={Paths.adminEditPlace()} element={<AdminRoute />}>
        <Route path={Paths.adminEditPlace()} element={<EditPlace />} />
      </Route>
      <Route path={Paths.adminReviews} element={<AdminRoute />}>
        <Route path={Paths.adminReviews} element={<Reviews />} />
      </Route>
      <Route path={Paths.adminPartners} element={<AdminRoute />}>
        <Route path={Paths.adminPartners} element={<Partners />} />
      </Route>
      <Route path={Paths.adminAddPlace} element={<AdminRoute />}>
        <Route path={Paths.adminAddPlace} element={<AddPlace />} />
      </Route>
      <Route path="*" element={<AdminRoute />}>
        <Route path="*" element={<Navigate to={Paths.admin} replace />} />
      </Route>
      <Route path={Paths.partnerHome} element={<PartnerRoute />}>
        <Route path={Paths.partnerHome} element={<PartnerHomepage />} />
      </Route>
      <Route path={Paths.partnerWelcome} element={<PartnerRoute />}>
        <Route path={Paths.partnerWelcome} element={<WelcomePage />} />
      </Route>
      <Route path={Paths.partnerAddPlace} element={<PartnerRoute />}>
        <Route path={Paths.partnerAddPlace} element={<PartnerAddPlace />} />
      </Route>
      <Route path={Paths.partnerAddPlaceFromList} element={<PartnerRoute />}>
        <Route
          path={Paths.partnerAddPlaceFromList}
          element={<AddPlaceFromList />}
        />
      </Route>
      <Route path={Paths.partnerImages} element={<PartnerRoute />}>
        <Route path={Paths.partnerImages} element={<PartnerImages />} />
      </Route>
      <Route path={Paths.partnerComments} element={<PartnerRoute />}>
        <Route path={Paths.partnerComments} element={<PartnerComments />} />
      </Route>
      <Route path={Paths.partnerContact} element={<PartnerRoute />}>
        <Route path={Paths.partnerContact} element={<PartnerContact />} />
      </Route>
      <Route path={Paths.partnerSettings} element={<PartnerRoute />}>
        <Route path={Paths.partnerSettings} element={<PartnerSettings />} />
      </Route>
      <Route path={Paths.partnerChangePassword} element={<PartnerRoute />}>
        <Route
          path={Paths.partnerChangePassword}
          element={<PartnerChangePassword />}
        />
      </Route>
      <Route path="*" element={<PartnerRoute />}>
        <Route path="*" element={<Navigate to={Paths.partnerHome} replace />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
