import { HorizontalDivider } from '../../../components/dividers/HorizontalDivider';
import { MainContent } from '../../../components/grid/MainContent';
import { LoadingSpinner } from '../../../components/spinner/LoadingSpinner';
import { usePlace } from '../../../store/placeContext';
import { useReviews } from '../../../store/reviewsContext';
import { ColumnFlexContainer } from '../homepage/PartnerHomepage.styles';
import CommentsSection from './components/CommentsSection';
import { TitleSection } from './components/TitleSection';

export const PartnerComments = () => {
  const { state: place } = usePlace();
  const { state: reviews } = useReviews();

  if (reviews && place) {
    return (
      <MainContent>
        <ColumnFlexContainer>
          <TitleSection title={place?.name} />
          <HorizontalDivider />
          <CommentsSection comments={reviews.reviews} />
        </ColumnFlexContainer>
      </MainContent>
    );
  } else {
    return (
      <MainContent>
        <LoadingSpinner />
      </MainContent>
    );
  }
};
