import styled from 'styled-components';

export const ImageContainer = styled.label`
  position: relative;
  cursor: pointer;
  text-align: center;

  input {
    display: none;
  }
`;

export const ImageAttribute = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
