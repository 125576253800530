import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MainButton } from '../../../../components/buttons/MainButton';
import ModalCarousel from '../../../../components/modals/ModalCarousel';
import { useIsTablet } from '../../../../hooks/useIsTablet';
import { scrollToElementById } from '../../../../utils/ScrollToElementById';

const FlexItem = styled.div`
  display: flex;
`;

const Text = styled.p`
  font-size: 1.1rem;
  width: 50%;
  text-align: center;
`;

const Header = styled.h2`
  font-size: 1.2rem;
  width: 30%;
  font-weight: 400;
`;

const Items = styled.div`
  width: 100%;
  display: flex;
`;

interface ComparedImageItemProps {
  title: string;
  oldImages: string[];
  newImages: string[];
}

export const ComparedImageItem = (props: ComparedImageItemProps) => {
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const { title, oldImages, newImages } = props;

  const [showAddedImages, setShowAddedImages] = useState(false);
  const [showDeletedImages, setShowDeletedImages] = useState(false);

  const deletedImages = oldImages.filter((item) => {
    return !newImages.includes(item);
  });
  const addedImages = newImages.filter((item) => !oldImages.includes(item));

  useEffect(() => {
    scrollToElementById('modal-carousel');
  }, [showAddedImages, showDeletedImages]);

  return (
    <>
      <FlexItem>
        <Header>{title}</Header>
        <Items>
          <Text>
            <MainButton
              title="Pokaż"
              onClick={() => setShowDeletedImages(true)}
              small={!!isTablet}
            />
          </Text>
          <Text>
            <MainButton
              title={t('buttons.show')}
              onClick={() => setShowAddedImages(true)}
              small={!!isTablet}
            />
          </Text>
        </Items>
      </FlexItem>
      {showDeletedImages && (
        <ModalCarousel
          images={deletedImages}
          onClose={() => setShowDeletedImages(false)}
          showDelete={false}
        />
      )}
      {showAddedImages && (
        <ModalCarousel
          images={addedImages}
          onClose={() => setShowAddedImages(false)}
          showDelete={false}
        />
      )}
    </>
  );
};
