import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    width: 50%;
  }
`;

const Title = styled.h1`
  font-size: 1.3rem;
  font-weight: 600;
`;

const Description = styled.p`
  white-space: pre-line;
`;

export const PartnerPanelInfo = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('partnerPanelInfo.title')}</Title>
      <Description>{t('partnerPanelInfo.description')}</Description>
    </Container>
  );
};
