import styled from 'styled-components';
import { breakpoints } from '../../styles/variables';

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  @media (min-width: ${breakpoints.desktop}) {
    width: 70vw;
  }

  @media (min-width: ${breakpoints.widescreen}) {
    width: 50vw;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
