import { ReactNode } from 'react';
import { Container, Content, Header } from './CardContainer.styles';

interface MainContainerProps {
  children: ReactNode;
  title: string;
}

export const CardContainer = (props: MainContainerProps) => {
  const { children, title } = props;

  return (
    <Container>
      <Header>{title}</Header>
      <Content>{children}</Content>
    </Container>
  );
};
