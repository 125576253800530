import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import AppRoutes from './routes/Routes';
import { AuthService } from './services/authService';
// eslint-disable-next-line import/extensions
import './i18n.ts';
import { AppProvider } from './store/AppProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useEffect(() => {
    AuthService.authenticate();
  }, []);

  return (
    <BrowserRouter>
      <AppProvider>
        <MainLayout>
          <AppRoutes />
          <ToastContainer />
        </MainLayout>
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
