import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../components/buttons/MainButton';
import { Header } from '../../components/header/Header';
import { Input } from '../../components/inputs/Input';
import { ModalSuccess } from '../../components/modals/ModalSuccess';
import { Paths } from '../../enums/Paths';
import { AuthService } from '../../services/authService';
import { breakpoints, colors } from '../../styles/variables';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.secondary};
  padding-left: 1rem;
`;

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  @media (min-width: ${breakpoints.desktop}) {
    width: 70vw;
  }

  @media (min-width: ${breakpoints.widescreen}) {
    width: 50vw;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;

const SmallButtonWrapper = styled.div`
  margin-top: 1rem;
`;

export const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isSent, setIsSent] = useState(false);

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await AuthService.resetPassword(params.id!, {
        newPassword,
        confirmationPassword,
      });
      setIsSent(true);
    } catch (err: any) {
      setError('resetPasswordForm.error');
    }
  };

  return (
    <>
      <Header>{t('resetPasswordForm.resetPassword')}</Header>
      <div>
        <FormContainer onSubmit={handleResetPassword}>
          <Input
            value={newPassword}
            label={t('resetPasswordForm.new')}
            type={showPassword ? 'text' : 'password'}
            required
            onChange={(e) => {
              setNewPassword(e.target.value);
              setError('');
            }}
          />
          <Input
            value={confirmationPassword}
            label={t('resetPasswordForm.confirm')}
            type={showPassword ? 'text' : 'password'}
            required
            onChange={(e) => {
              setConfirmationPassword(e.target.value);
              setError('');
            }}
            error={error}
          />
          <SmallButtonWrapper>
            <MainButton
              title={t('buttons.showPasswords')}
              onClick={() => setShowPassword((prevState) => !prevState)}
              small
              btnColor={colors.secondary}
            />
          </SmallButtonWrapper>
          <ButtonWrapper>
            <MainButton type="submit" title={t('buttons.send')} />
          </ButtonWrapper>
        </FormContainer>
        <StyledLink to={Paths.login}>{t('resetPasswordForm.back')}</StyledLink>
        {isSent && (
          <ModalSuccess
            onClose={() => {
              setIsSent(false);
              navigate(Paths.login);
            }}
            title={t('resetPasswordForm.modalSuccess')}
          />
        )}
      </div>
    </>
  );
};
