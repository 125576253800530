import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { MainButton } from '../../../../components/buttons/MainButton';
import { ModalConfirmation } from '../../../../components/modals/ModalConfirmation';
import { Paths } from '../../../../enums/Paths';
import { WithLoadingProps } from '../../../../hocs/interfaces/WithLoadingProps';
import { withLoading } from '../../../../hocs/WithLoading';
import { PlacesService } from '../../../../services/placesService';
import { UserService } from '../../../../services/userService';
import { usePlace } from '../../../../store/placeContext';
import { clearLocalStorage } from '../../../../utils/ClearLocalStorage';

const SettingsSection = (props: WithLoadingProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setLoading } = props;
  const { state: place } = usePlace();

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showDeletePlaceModal, setShowDeletePlaceModal] = useState(false);

  const deleteAccountHandler = async () => {
    setShowDeleteAccountModal(false);
    setLoading(true);

    await UserService.deleteCurrentUser();
    clearLocalStorage();
    window.location.reload();

    setLoading(false);
  };

  const deletePlaceHandler = async () => {
    setShowDeletePlaceModal(false);
    setLoading(true);

    await PlacesService.deletePlace(place._id);
    window.location.reload();

    setLoading(false);
  };

  return (
    <>
      <MainButton
        onClick={() => navigate(Paths.partnerChangePassword)}
        title={t(['settings.changePassword'])}
      />
      <MainButton
        onClick={() => setShowDeleteAccountModal(true)}
        title={t(['settings.deleteAccount'])}
      />
      <MainButton
        onClick={() => setShowDeletePlaceModal(true)}
        title={t(['settings.deletePlace'])}
      />

      {showDeleteAccountModal && (
        <ModalConfirmation
          onAccept={deleteAccountHandler}
          onClose={() => setShowDeleteAccountModal(false)}
          title={t('settings.deleteAccountModal')}
        />
      )}
      {showDeletePlaceModal && (
        <ModalConfirmation
          onAccept={deletePlaceHandler}
          onClose={() => setShowDeletePlaceModal(false)}
          title={`${t('settings.deletePlaceModal')} - ${place.name}?`}
        />
      )}
    </>
  );
};

export default withLoading(SettingsSection);
