import styled from 'styled-components';
import { useAuth } from '../../../hooks/useAuth';
import { AuthService } from '../../../services/authService';
import { breakpoints, colors } from '../../../styles/variables';
import pipe from '../../../assets/icons/pipe.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../../enums/Paths';
import { clearLocalStorage } from '../../../utils/ClearLocalStorage';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface ButtonsProps {
  paddingLeft?: string;
}

const Button = styled.a<ButtonsProps>`
  cursor: pointer;
  text-decoration: none;
  color: ${colors.white};
  padding-left: ${(props) => props.paddingLeft ?? '1rem'};
  font-size: 0.85rem;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: ${(props) => props.paddingLeft ?? '2rem'};
  }
`;

const PipeWrapper = styled.div`
  padding: 0px 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  padding-left: 1rem;
  font-size: 0.85rem;
`;

export const ButtonsDesktop = () => {
  const { t } = useTranslation();

  const auth = useAuth();

  const logoutHandler = async () => {
    await AuthService.logout();
    clearLocalStorage();
    window.location.reload();
  };

  return (
    <ButtonsWrapper>
      <StyledLink to={Paths.admin}>{t('navigationAdmin.published')}</StyledLink>
      <StyledLink to={Paths.adminUnpublished}>
        {t('navigationAdmin.unpublished')}
      </StyledLink>
      <StyledLink to={Paths.adminStagedChanges}>
        {t('navigationAdmin.newChanges')}
      </StyledLink>
      <StyledLink to={Paths.adminReviews}>
        {t('navigationAdmin.reviews')}
      </StyledLink>
      <StyledLink to={Paths.adminPartners}>
        {t('navigationAdmin.partners')}
      </StyledLink>
      <StyledLink to={Paths.adminAddPlace}>
        {t('navigationAdmin.addPlace')}
      </StyledLink>
      {auth && (
        <>
          <PipeWrapper>
            <img src={pipe} width={2} height={24} alt="pipe" loading="eager" />
          </PipeWrapper>
          <Button paddingLeft="0" onClick={logoutHandler}>
            {t('navigationAdmin.logout')}
          </Button>
        </>
      )}
    </ButtonsWrapper>
  );
};
