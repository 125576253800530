import { ChangeEvent, SetStateAction, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import { GoogleService } from '../../services/googleService';
import AutocompleteMui, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { PlacesService } from '../../services/placesService';
import {
  AddressComponent,
  AddressDetailsTypesEnum,
} from '../../interfaces/AutocompleteDetails';
import { findInAddress } from './FindInAddress';
import { ObjectType } from '../../interfaces/ObjectType';
import { getKeyByValue } from '../../utils/GetKeyByValue';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  height: 3rem;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 80%;
  }
`;

const AutocompleteInfoWrapper = styled.div`
  width: 70%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

const AutocompleteHeader = styled.h3`
  margin: 0;
`;

const AutocompleteText = styled.p`
  font-size: 0.9rem;
`;

const AutocompleteInfo = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0;
`;

interface AutocompleteOptionsProps {
  description: string;
  place_id: string;
}

interface AutocompleteProps {
  statesTranslations?: ObjectType;
  setState: React.Dispatch<SetStateAction<string>>;
  setName: React.Dispatch<SetStateAction<string>>;
  setCity: React.Dispatch<SetStateAction<string>>;
  setStreet: React.Dispatch<SetStateAction<string>>;
  setZip: React.Dispatch<SetStateAction<string>>;
  setPhoneNumber: React.Dispatch<SetStateAction<string>>;
  setDescription: React.Dispatch<SetStateAction<string>>;
  setWebPage: React.Dispatch<SetStateAction<string>>;
  setGoogleId: React.Dispatch<SetStateAction<string>>;
}

export const Autocomplete = (props: AutocompleteProps) => {
  const {
    statesTranslations,
    setCity,
    setDescription,
    setName,
    setPhoneNumber,
    setState,
    setStreet,
    setWebPage,
    setZip,
    setGoogleId,
  } = props;

  const { t } = useTranslation();

  const [autocompleteOptions, setAutocompleteOptions] = useState<
    AutocompleteOptionsProps[]
  >([]);

  const setAddress = (addressComponent: AddressComponent[]) => {
    const city = findInAddress(addressComponent, AddressDetailsTypesEnum.city);
    if (city) {
      setCity(city);
    }

    const street = findInAddress(
      addressComponent,
      AddressDetailsTypesEnum.street,
    );
    const streetNumber = findInAddress(
      addressComponent,
      AddressDetailsTypesEnum.streetNumber,
    );
    if (street) {
      setStreet(`${street}${streetNumber ? ` ${streetNumber}` : ''}`);
    }

    const zip = findInAddress(
      addressComponent,
      AddressDetailsTypesEnum.postalCode,
    );
    if (zip) {
      setZip(zip);
    }

    const state = findInAddress(
      addressComponent,
      AddressDetailsTypesEnum.state,
    );
    if (state && statesTranslations) {
      const stateValue = getKeyByValue(statesTranslations, state);

      if (stateValue) {
        setState(stateValue);
      }
    }
  };

  const handleSearch = async (search: string) => {
    const places = await GoogleService.findAutocompletePlaces(search);

    const options = places.predictions.map((item: AutocompleteOptionsProps) => {
      return { description: item.description, place_id: item.place_id };
    });

    setAutocompleteOptions(options);
  };

  const onSelect = async (
    _: SyntheticEvent<Element, Event>,
    val: string | null,
  ) => {
    const placeId = autocompleteOptions.find(
      (item) => item.description === val,
    )?.place_id;

    if (!placeId) {
      return;
    }

    const response = await PlacesService.fetchPlaceAutocompleteDetails(placeId);

    if (response.name) {
      setName(response.name);
    }
    if (response.formatted_phone_number) {
      setPhoneNumber(response.formatted_phone_number);
    }
    if (response.website) {
      setWebPage(response.website);
    }
    if (response.editorial_summary?.overview) {
      setDescription(response.editorial_summary.overview);
    }
    if (response.address_components) {
      setAddress(response.address_components);
    }
    if (response.place_id) {
      setGoogleId(response.place_id);
    }
  };

  return (
    <>
      <AutocompleteInfoWrapper>
        <AutocompleteHeader>{t('autocomplete.header')}</AutocompleteHeader>
        <AutocompleteText>{t('autocomplete.description')}</AutocompleteText>
        <AutocompleteInfo>{t('autocomplete.info')}</AutocompleteInfo>
      </AutocompleteInfoWrapper>
      <Container>
        <AutocompleteMui
          freeSolo={true}
          options={autocompleteOptions.map((item) =>
            item.description.toString(),
          )}
          fullWidth
          onChange={onSelect}
          filterOptions={(x: string[]) => x}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                handleSearch(e.target.value);
              }}
              type="text"
              placeholder={t('search')}
              fullWidth
            />
          )}
        />
      </Container>
    </>
  );
};
