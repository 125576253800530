import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth';
import { breakpoints, colors } from '../../styles/variables';
import { Buttons } from './buttons/Buttons';
import { Logo } from './Logo';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;
  background: ${colors.secondary};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0.8rem 2rem;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0.8rem 3rem;
  }

  @media screen and (min-width: ${breakpoints.widescreen}) {
    padding: 0.8rem 5rem;
  }

  @media screen and (min-width: ${breakpoints.uhd}) {
    padding: 0.8rem 10rem;
  }
`;

export const Navbar = () => {
  const auth = useAuth();

  return (
    <Container>
      <Logo size={33} />
      {auth && <Buttons />}
    </Container>
  );
};
