import { Tooltip } from '@mui/material';
import { Cell } from '../../../interfaces/Cell';
import { FlattenedPlace } from '../../../pages/admin/unpublished/helpers/flattenPlaces';
import { StyledIconButton } from './ButtonColumns.styles';

interface ButtonColumnProps {
  id: string;
  onClick: (place: FlattenedPlace) => void;
  title: string;
  icon: JSX.Element;
}

export const ButtonColumn = (props: ButtonColumnProps) => {
  const { id, onClick, title, icon } = props;

  return {
    id,
    Cell: ({ row }: Cell) => (
      <Tooltip title={title} placement="top">
        <StyledIconButton onClick={onClick.bind(null, row.original)}>
          {icon}
        </StyledIconButton>
      </Tooltip>
    ),
  };
};
