import axios from 'axios';
import { toast } from 'react-toastify';
import { getConfig } from '../config/GetConfig';
import { clearLocalStorage } from '../utils/ClearLocalStorage';

const instance = axios.create({
  baseURL: getConfig().api.url,
  withCredentials: true,
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/refresh' && err.response) {
      if (
        (err.response.status === 401 || err.response.status === 403) &&
        originalConfig.url !== '/auth/login-admin'
      ) {
        try {
          await instance.get('/auth/refresh');
          return await instance(originalConfig);
        } catch (_error) {
          clearLocalStorage();
          return Promise.reject(_error);
        }
      } else {
        if (err.response.data?.messageForUser) {
          toast.error(err.response.data.messageForUser);
        }
      }
    }
    return Promise.reject(err);
  },
);
export default instance;
