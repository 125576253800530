export interface ValidateContactFormProps {
  name?: string;
  email?: string;
  phoneNumber?: string;
  text?: string;
}

export const validateContactForm = (values: ValidateContactFormProps) => {
  const errors: ValidateContactFormProps = {};
  if (!values.name) {
    errors.name = 'contactValidation.name';
  }
  if (!values.email) {
    errors.email = 'contactValidation.email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'contactValidation.incorrectEmail';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'contactValidation.phoneNumber';
  } else if (
    !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g.test(values.phoneNumber)
  ) {
    errors.phoneNumber = 'contactValidation.incorrectPhoneNumber';
  }
  if (!values.text) {
    errors.text = 'contactValidation.text';
  }

  return errors;
};
