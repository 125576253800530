import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiArrowBack, BiSave } from 'react-icons/bi';
import styled from 'styled-components';
import { Autocomplete } from '../../../../../components/autocomplete/Autocomplete';
import { AcceptedDog } from '../../../../../components/forms/AcceptedDog';
import {
  ValidatePlaceForm,
  ValidatePlaceFormProps,
} from '../../../../../components/forms/validators/ValidatePlaceForm';
import { Dropdown } from '../../../../../components/inputs/Dropdown';
import { Input } from '../../../../../components/inputs/Input';
import { Textarea } from '../../../../../components/inputs/Textarea';
import { ModalFailure } from '../../../../../components/modals/ModalFailure';
import { ModalSuccess } from '../../../../../components/modals/ModalSuccess';
import { IconButton } from '../../../../../components/navigationPartner/buttons/IconButton';
import { SelectFeatureButton } from '../../../../../components/navigationPartner/buttons/SelectFeatureButton';
import { LoadingSpinner } from '../../../../../components/spinner/LoadingSpinner';
import { AcceptedDogsEnum } from '../../../../../enums/AcceptedDogsEnum';
import { FormTypes } from '../../../../../interfaces/FormTypes';
import { PlaceDTO } from '../../../../../interfaces/Places';
import { PlacesService } from '../../../../../services/placesService';
import { FlagsContextTypes, useFlags } from '../../../../../store/flagsContext';
import { PlaceContextTypes, usePlace } from '../../../../../store/placeContext';
import { ObjectType } from '../../../../../interfaces/ObjectType';
import { scrollToElementById } from '../../../../../utils/ScrollToElementById';
import { IconsContainer } from '../../PartnerHomepage.styles';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const FormContainer = styled.form`
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-right: 5rem;
  overflow: auto;
`;

const SendButton = styled.button`
  border: none;
  background: none;
`;

interface PlaceFormProps {
  type: FormTypes;
  onCancelEdit?: () => void;
  onAddSuccess?: () => void;
}

export const PartnerPlaceForm = (props: PlaceFormProps) => {
  const { t } = useTranslation();
  const { state: place, dispatch } = usePlace();
  const { dispatch: flagsDispatch } = useFlags();

  const { type, onCancelEdit, onAddSuccess } = props;

  const [categories, setCategories] = useState([]);
  const [categoriesTranslations, setCategoriesTranslations] =
    useState<ObjectType>();
  const [states, setStates] = useState([]);
  const [statesTranslations, setStatesTranslations] = useState<ObjectType>();

  const isEdit = type === FormTypes.edit;

  const [isAddressOptional, setIsAddressOptional] = useState(
    isEdit ? !place.address : false,
  );

  const [category, setCategory] = useState(isEdit ? place.category : 'other');
  const [state, setState] = useState(
    isEdit ? place.address?.state : 'mazowieckie',
  );
  const [name, setName] = useState(isEdit ? place.name : '');
  const [city, setCity] = useState(isEdit ? place.address?.city : '');
  const [street, setStreet] = useState(isEdit ? place.address?.street : '');
  const [zip, setZip] = useState(isEdit ? place.address?.zip : '');
  const [phoneNumber, setPhoneNumber] = useState(
    isEdit ? place.phoneNumber : '',
  );
  const [email, setEmail] = useState(isEdit ? place.email : '');
  const [description, setDescription] = useState(
    isEdit ? place.description : '',
  );
  const [webPage, setWebPage] = useState(isEdit ? place.webPage : '');
  const [selectedPetFeatures, setSelectedPetFeatures] = useState<string[]>(
    isEdit ? place.petFeatures : [],
  );
  const [googleId, setGoogleId] = useState(isEdit ? place.googleId ?? '' : '');
  const [acceptedDogs, setAcceptedDogs] = useState<AcceptedDogsEnum[]>(
    isEdit ? place.acceptedDogs : [],
  );

  useEffect(() => {
    const fetchTypes = async () => {
      const categoriesRes = await PlacesService.getCategories();
      const categoriesTranslationsRes =
        await PlacesService.getCategoriesTranslations();
      const statesRes = await PlacesService.getStates();
      const statesTranslationsRes = await PlacesService.getStatesTranslations();

      setCategories(categoriesRes);
      setCategoriesTranslations(categoriesTranslationsRes);
      setStates(statesRes);
      setStatesTranslations(statesTranslationsRes);
    };

    fetchTypes();
  }, []);

  const [status, setStatus] = useState({
    isSent: false,
    isSending: false,
    isError: false,
  });
  const [errors, setErrors] = useState<ValidatePlaceFormProps>({});

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrors({});
    setStatus((prevState) => {
      return {
        ...prevState,
        isSending: true,
      };
    });

    const validateErrors = ValidatePlaceForm({
      category,
      state,
      name,
      city,
      street,
      zip,
      phoneNumber,
      email,
      webPage,
      isAddressOptional,
    });

    if (Object.keys(validateErrors).length > 0) {
      setErrors(validateErrors);
      setStatus((prevState) => {
        return {
          ...prevState,
          isError: true,
          isSending: false,
        };
      });

      return;
    }

    const sendData = async () => {
      try {
        const dataToSend: PlaceDTO = {
          name,
          category,
          description,
          webPage,
          petFeatures: selectedPetFeatures,
          phoneNumber,
          email,
          acceptedDogs,
          googleId,
        };
        if (!isAddressOptional) {
          dataToSend.address = {
            city,
            street,
            zip,
            state,
            country: 'PL',
          };
        }

        if (type === FormTypes.edit && place?._id) {
          const editedPlace = await PlacesService.partnerEditPlace(
            place._id,
            dataToSend,
          );
          dispatch({ type: PlaceContextTypes.setPlace, payload: editedPlace });
        } else {
          await PlacesService.partnerAddPlace(dataToSend);
          flagsDispatch({
            type: FlagsContextTypes.setPlaceAdded,
            payload: true,
          });
        }

        setStatus((prevState) => {
          return {
            ...prevState,
            isSent: true,
            isSending: false,
          };
        });
      } catch (error) {
        setStatus((prevState) => {
          return {
            ...prevState,
            isError: true,
            isSending: false,
          };
        });
      }
    };

    sendData();
  };

  useEffect(() => {
    scrollToElementById('spinner');
    scrollToElementById('modal');
  }, [status]);

  if (categories.length === 0 || states.length === 0) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Container>
          <FormContainer onSubmit={submitHandler}>
            <Autocomplete
              statesTranslations={statesTranslations}
              setCity={setCity}
              setDescription={setDescription}
              setName={setName}
              setPhoneNumber={setPhoneNumber}
              setState={setState}
              setStreet={setStreet}
              setWebPage={setWebPage}
              setZip={setZip}
              setGoogleId={setGoogleId}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAddressOptional}
                  onChange={() =>
                    setIsAddressOptional((prevState) => !prevState)
                  }
                />
              }
              label={t('placeForm.isAddressOptional')}
            />
            <Dropdown
              label={t('place.category')}
              onChange={(e) => {
                setCategory(e.target.value);
                setSelectedPetFeatures([]);
                setErrors({ ...errors, category: undefined });
              }}
              required
              value={category}
              error={errors.category}
            >
              {categories.map((item: string, idx: number) => (
                <MenuItem key={idx} value={item}>
                  {categoriesTranslations ? categoriesTranslations[item] : item}
                </MenuItem>
              ))}
            </Dropdown>
            <Input
              label={t('place.name')}
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrors({ ...errors, name: undefined });
              }}
              required
              error={errors.name}
            />
            <Dropdown
              label={t('place.state')}
              onChange={(e) => {
                setState(e.target.value);
                setErrors({ ...errors, state: undefined });
              }}
              required={!isAddressOptional}
              value={state}
              error={errors.state}
              disabled={isAddressOptional}
            >
              {states.map((item: string, idx: number) => (
                <MenuItem key={idx} value={item}>
                  {statesTranslations ? statesTranslations[item] : item}
                </MenuItem>
              ))}
            </Dropdown>
            <Input
              label={t('place.city')}
              type="text"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                setErrors({ ...errors, city: undefined });
              }}
              required={!isAddressOptional}
              error={errors.city}
              disabled={isAddressOptional}
            />
            <Input
              label={t('place.zip')}
              type="text"
              value={zip}
              onChange={(e) => {
                setZip(e.target.value);
                setErrors({ ...errors, zip: undefined });
              }}
              required={!isAddressOptional}
              error={errors.zip}
              disabled={isAddressOptional}
            />
            <Input
              label={t('place.street')}
              type="text"
              value={street}
              onChange={(e) => {
                setStreet(e.target.value);
                setErrors({ ...errors, street: undefined });
              }}
              required={!isAddressOptional}
              error={errors.street}
              disabled={isAddressOptional}
            />
            <Input
              label={t('place.phoneNumber')}
              type="text"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setErrors({ ...errors, phoneNumber: undefined });
              }}
              error={errors.phoneNumber}
            />
            <Input
              label={t('place.email')}
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors({ ...errors, email: undefined });
              }}
              error={errors.email}
            />
            <Input
              label={t('place.webPage')}
              type="text"
              value={webPage}
              onChange={(e) => {
                setWebPage(e.target.value);
                setErrors({ ...errors, webPage: undefined });
              }}
              error={errors.webPage}
            />
            <Textarea
              label={t('place.description')}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <SelectFeatureButton
              selectedPetFeatures={selectedPetFeatures}
              setSelectedPetFeatures={setSelectedPetFeatures}
              category={category}
            />
            <AcceptedDog
              acceptedDogs={acceptedDogs}
              setAcceptedDogs={setAcceptedDogs}
            />
            <IconsContainer>
              {onCancelEdit && (
                <IconButton
                  text={t('buttons.cancel')}
                  Icon={BiArrowBack}
                  onClick={onCancelEdit}
                />
              )}
              <SendButton type={'submit'}>
                <IconButton text={t('buttons.save')} Icon={BiSave} />
              </SendButton>
            </IconsContainer>
          </FormContainer>
        </Container>
        {status.isSent && (
          <ModalSuccess
            onClose={() => {
              setStatus((prevState) => {
                return {
                  ...prevState,
                  isSent: false,
                };
              });
              if (onCancelEdit) {
                onCancelEdit();
              }
              if (onAddSuccess) {
                onAddSuccess();
              }
            }}
            title={t([
              `placeForm.${
                type === FormTypes.edit ? 'partnerModalEdit' : 'partnerModalAdd'
              }`,
            ])}
          />
        )}
        {status.isError && (
          <ModalFailure
            onClose={() =>
              setStatus((prevState) => {
                return {
                  ...prevState,
                  isError: false,
                };
              })
            }
          />
        )}
        {status.isSending && <LoadingSpinner />}
      </>
    );
  }
};
