import { AcceptedDogsEnum } from '../../../../enums/AcceptedDogsEnum';
import { Address, Place } from '../../../../interfaces/Places';

export interface IStagedChanges {
  acceptedDogs?: AcceptedDogsEnum[] | undefined;
  address?: Address | undefined;
  avatar?: string | undefined;
  category?: string | undefined;
  description?: string | undefined;
  webPage?: string | undefined;
  email?: string | undefined;
  googleId?: string | undefined;
  images?: string[] | undefined;
  name?: string | undefined;
  petFeatures?: string[] | undefined;
  phoneNumber?: string | undefined;
}

export const IStagedChangesKeys = [
  'acceptedDogs',
  'address',
  'avatar',
  'category',
  'description',
  'webPage',
  'email',
  'googleId',
  'images',
  'name',
  'petFeatures',
  'phoneNumber',
];

export const flattenStagedChanges = (place: Place | IStagedChanges) => {
  return {
    acceptedDogs: place.acceptedDogs ?? undefined,
    address: place.address ?? undefined,
    avatar: place.avatar ?? undefined,
    category: place.category ?? undefined,
    description: place.description ?? undefined,
    webPage: place.webPage ?? undefined,
    email: place.email ?? undefined,
    googleId: place.googleId ?? undefined,
    images: place.images ?? undefined,
    name: place.name ?? undefined,
    petFeatures: place.petFeatures ?? undefined,
    phoneNumber: place.phoneNumber ?? undefined,
  };
};
